import React, {useState} from "react";
import {
    Grid,
    LinearProgress,
    Select,
    OutlinedInput,
    MenuItem,
    Button, CircularProgress
} from "@material-ui/core";
import {useTheme} from "@material-ui/styles";
import {
    ResponsiveContainer,
    ComposedChart,
    AreaChart,
    LineChart,
    Line,
    Area,
    PieChart,
    Pie,
    Cell,
    YAxis,
    XAxis, Bar, BarChart,Tooltip, Legend, CartesianGrid
} from "recharts";

import axios from "axios";
import {server_base_url} from "../../context/UserContext";

// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import {Typography} from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import APIKeyCreation from "./APIKeyCreation";
// import {BarChart} from "@material-ui/icons";
// import APIKeyCreation from "../../pages/dashboard";

import OnboardComponent from "./OnboardComponent";

const mainChartData = [
  {
    name: '0% Insurance 1 Month Loan',
    amount: 0.110765945670178,
  },
  {
    name: '0% Insurance Term Loan',
    amount: 0.10783324943235,
  },
  {
    name: 'Dev Housing Employed',
    amount: 0.116296346752076,
  },
  {
    name: 'Employed 1 Month Loan',
    amount: 0.409549300461154,
  },
  {
    name: 'Employed 1 Month Loan Capped',
    amount: 0.281629783274285,
  },
  {
    name: 'Employed Term Loan',
    amount: 0.172536964556855,
  },
  {
    name: 'Enterprise Development',
    amount: 0.106084722264542,
  },
    {
    name: 'Legacy Loan',
    amount: 0.507164460774218,
  },
        {
    name: 'Pension 1 Month Loan',
    amount: 0.350020090359162,
  },
            {
    name: 'Pension Term Loan',
    amount: 0.184139853539764,
  },
];

const API_URL = server_base_url + "/api/";

var api_data = []
    const getInsights = () => {

        var bodyFormData = new FormData();
        bodyFormData.append('email', "'" + localStorage.getItem('id_token') + "'");
        // bodyFormData.append('applicationID', application_id);
        //bodyFormData.append('fullObj', JSON.stringify(obj));
        axios({
            method: "get",
            url: API_URL + 'get_insights/?email='+ localStorage.getItem('id_token'),
            headers: {"Content-Type": "multipart/form-data"},
        })
            .then(function (response) {
              var sortedResults = [];


                var parsedJSON = response.data;
                for (var i = 0; i < parsedJSON.length; i++) {

                    sortedResults = parsedJSON[i].graph_data

                    // sortedResults.push({
                    //     name: ''+parsedJSON[i].date_banked+'',
                    //     amount: parsedJSON[i].amount,
                    // })
                }

                api_data = sortedResults;

                 console.log('DATA MIKYA')
                 console.log(api_data)

                console.log('XXXX GGG')
                console.log(mainChartData)



            })
            .catch(function (response) {
            });
    }


    getInsights();

const PieChartData = [
    {name: "Group A", value: 400, color: "primary"},
    {name: "Group B", value: 300, color: "secondary"},
    {name: "Group C", value: 300, color: "warning"},
    {name: "Group D", value: 200, color: "success"},
];


export default function Dashboard(props) {
    var classes = useStyles();
    var theme = useTheme();

    // local
    var [mainChartState, setMainChartState] = useState("monthly");
    var [onboard, setOnboard] = useState(localStorage.getItem('verified_status'));

    //setOnboard(true);

    return (
        <>
            {onboard && onboard === "False" ? (<OnboardComponent/>) : (
                <>
                    <PageTitle title="Dashboard"/>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Widget
                                bodyClass={classes.mainChartBody}
                                header={
                                    <div className={classes.mainChartHeader}>
                                        <Typography
                                            variant="h5"
                                            color="text"
                                            colorBrightness="secondary"
                                        >
                                            Data Insights (Derived from Datasets and credit applications)
                                        </Typography>
                                    </div>
                                }
                            >
                                <ResponsiveContainer width="100%" minWidth={500} height={350}>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={api_data}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                        barSize={20}
                                    >
                                        <XAxis dataKey="name" scale="point" padding={{left: 10, right: 10}}/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Legend/>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <Bar dataKey="amount" fill="#8884d8" background={{fill: '#eee'}}/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Widget>
                        </Grid>
                    </Grid>
                    <br/>

                </>
            )};
            <br/>


        </>
    );
}


function switchView(value) {
    if (value == 'home') {
        this.setState({
            home: true,
            profile: false
        })
    } else {
        this.setState({
            home: false,
            profile: true
        })
    }
}

function displayAPI() {

    const {errors, name, email, my_api_keys, my_connected_apps} = this.state;

    var dashboard_data = <section id="tabs" className="dashboard">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="content">
                        <div className="row">
                            <div className="col-xs-12 ">
                                <nav>
                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        <a className={this.state.home ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                           id="nav-home-tab" data-toggle="tab" onClick={() => this.switchView('home')}
                                           role="tab" aria-controls="nav-home"
                                           aria-selected={this.state.home ? 'true' : 'false'}>API Keys</a>
                                        <a className={this.state.profile ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                           id="nav-profile-tab" data-toggle="tab"
                                           onClick={() => this.switchView('profile')} role="tab"
                                           aria-controls="nav-profile"
                                           aria-selected={this.state.profile ? 'false' : 'true'}>Connected Apps /
                                            Websites</a>
                                        {/* <a className={this.state.profile ? 'nav-item nav-link active' : 'nav-item nav-link'} id="nav-profile-tab" data-toggle="tab" onClick={() => this.switchView('profile')}  role="tab" aria-controls="nav-profile" aria-selected={this.state.profile ? 'false' : 'true'}>Profile</a> */}
                                    </div>
                                </nav>
                                <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                    <div className={this.state.home ? 'tab-pane fade show active' : 'tab-pane fade'}
                                         id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <form noValidate onSubmit={this.registerSubmit}>
                                            <div class="form-row">
                                                <div class="form-group col-md-12">
                                                    <label htmlFor="name">App Name</label> <br/>
                                                    <input
                                                        type="text"
                                                        className="input-control"
                                                        placeholder="Name of APP"
                                                        id="name"
                                                        value={name}
                                                        onChange={this.onChangeRegister}
                                                        error={errors.name}
                                                        // className={classnames('', {
                                                        //   invalid: errors.name
                                                        // })}
                                                    />{' '}
                                                    <button type="submit" className="btn btn-md btn-register">
                                                        Create API Key
                                                    </button>
                                                    <br/>
                                                    <span className="text-danger">{errors.name}</span>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-12">
                                                    {/* <label htmlFor="Email">Email</label> <br /> */}
                                                    <input
                                                        type="hidden"
                                                        className="input-control"
                                                        placeholder="Enter your email"
                                                        id="email"
                                                        value={localStorage.getItem("user_email")}
                                                        onChange={this.onChangeRegister}
                                                        error={errors.email}
                                                        // className={classnames('', {
                                                        //   invalid: errors.email
                                                        // })}
                                                    />{' '}
                                                </div>
                                            </div>
                                        </form>
                                        <table className="table">
                                            <thead>
                                            <th>APP Name</th>
                                            <th>API Secret</th>
                                            <th>Delete</th>
                                            </thead>
                                            <tbody>
                                            {/* {this.renderTableRows(this.state.my_api_keys)} */}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={this.state.profile ? 'ab-pane fade show active' : 'ab-pane fade'}
                                         id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                        <table className="table">
                                            <thead>
                                            <th>API</th>
                                            <th>Connection Status</th>
                                            </thead>
                                            <tbody>
                                            {/* {this.renderConnectedTableRows(this.state.my_connected_apps)} */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    return dashboard_data
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
    var array = new Array(length).fill();
    let lastValue;

    return array.map((item, index) => {
        let randomValue = Math.floor(Math.random() * multiplier + 1);

        while (
            randomValue <= min ||
            randomValue >= max ||
            (lastValue && randomValue - lastValue > maxDiff)
            ) {
            randomValue = Math.floor(Math.random() * multiplier + 1);
        }

        lastValue = randomValue;

        return {value: randomValue};
    });
}

function getMainChartData() {
    var resultArray = [];
    var resultArrayRaw = [];
    var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
    var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
    var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

    for (let i = 0; i < tablet.length; i++) {
        resultArray.push({
            tablet: tablet[i].value,
            desktop: desktop[i].value,
            mobile: mobile[i].value,
        });
    }

    return resultArrayRaw;
}
