import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { logoutUser, registerAPI , deleteKey, getConnections} from '../../redux/actions/authActions';
import './api.css';
import axios from "axios";
// import classnames from 'classnames';
// import { registerAPI } from '../../redux/actions/authActions';
// import  BulletList  from 'react-content-loader';
// import { Link, withRouter } from 'react-router-dom';
// const MyBulletListLoader = () => <BulletList />
// import setAuthToken from '../../utils/setAuthToken';
// import { confirmAlert } from 'react-confirm-alert';
import {mikyaLoginUser} from "../../context/UserContext";
import {Grid} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Widget from "../../components/Widget";
import Table from "./components/Table/Table";
// import mock from "./mock";
import mock from "../dashboard/mock";
import {makeStyles} from "@material-ui/styles";
import PageTitle from "../../components/PageTitle";
import Select from "react-select"; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// const constants = {
//   "url": "http://localhost:8000/api/credential/?format=json"
// }
//import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
// import mock from "../dashboard/mock";
import {server_base_url} from "../../context/UserContext";

const constants = {
  "url": server_base_url+"/api/credential/?format=json"
}
const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

class DevDocs extends Component {


  constructor(props) {
    super(props);
    this.state = {
        home: true,
        profile: false,

        name: '',
        email: '',
        errors: {},
        my_api_keys: [],
        my_connected_apps: [],

      users: [],
      usersLength: 0,
      message: "",
    };


}



  render() {
   var api_dashboard =      <>
      <PageTitle title="Developer Documentation" />

      <Grid container spacing={4}>

        <Grid item xs={12}>
            <div>

                {/*<SyntaxHighlighter language="markdown" style={docco} wrapLongLines={true}>*/}
                    <b># creditais-android-library</b>

                   <br/>
                   <br/>

                    Connect your app in 4 easy steps
                <br/>
                <br/>
                    <b># Step ONE</b>
                <br/>
                <br/>

                    Get an API Key from: https://app.creditais.com/
                <br/>
                <br/>

                    <b># Step TWO</b>
                <br/>

                    Request the library from Creditais, and the library to your build.gradle file
                <br/>

                    ```
                    compile project(":creditaisLibrary")
                    ```
                    <br/>
                    <br/>
                    <b># Step Three</b>
                   <br/>
                   <br/>

                    Add the the string below in your strings.xml file
                    <br/>
                    <br/>
                    ```
                    <string name="creditais_api_key">DEVELOPER_API_KEY_OBTAINED_FROM_STEP_ONE</string>
                    ```
                    <br/>
                    <br/>
                    <b># Step Four</b>
                    <br/>
                    <br/>
                    Start the Creditais with this line of code in the main activity
                    <br/>
                    <br/>
                    ```
                    CreditaisCredentials.start(MainActivity.this,getResources().getString(R.string.creditais_api_key));
                    ```
                    <br/>
                    <br/>
                    Thats it, you are done! Visit your dashboard and get credit scores
                    <br/>
                    <br/>
                {/*</SyntaxHighlighter>*/}


            </div>


        </Grid>
      </Grid>
    </>

return api_dashboard

  }
}



export default DevDocs;
