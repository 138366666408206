import React, {useState, useEffect} from "react";
import {
    Grid,
    CircularProgress,
    Typography,
    Button,
    Tabs,
    Tab,
    TextField,
    Fade,
} from "@material-ui/core";
import {withRouter} from "react-router-dom";

// styles
import useStyles from "./login/styles";
// context
import {useUserDispatch, loginUser, mikyaLoginUser, server_base_url} from "../context/UserContext";
import Select from 'react-select';
import axios from "axios";
import * as Yup from "yup";
import {Container, Nav, Navbar} from "react-bootstrap";
import logo_2 from "./web_files/images/logo_2.png";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mockup from "./web_files/images/mockup.png";
import blob_two from "./web_files/images/blob_two.png";
import Swal from "sweetalert2";
import {Formik, Field, Form, useField, useFormikContext, ErrorMessage} from 'formik';


function NewPasswordComponent(props) {
    var classes = useStyles();

    //const { addToast } = useToasts();
    // global
    var userDispatch = useUserDispatch();

    // local
    var [isLoading, setIsLoading] = useState(false);
    var [error, setError] = useState(null);
    var [activeTabId, setActiveTabId] = useState(1);
    var [firstNameValue, setFirstNameValue] = useState("");
    var [loginValue, setLoginValue] = useState();
    var [passwordValue, setPasswordValue] = useState();
    var [lastNameValue, setLastNameValue] = useState("");
    var [tokenValue, setTokenValue] = useState();
    var [phoneNumber, setPhoneNumberValue] = useState("");
    var [show_error_modal, setModalValue] = useState(false);
    var [clientType, setClientType] = useState("Business");

    const urlSearchParams = new URLSearchParams(props.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let set_user = false;

    function setPhone(id) {

        setPhoneNumberValue(id);

    }

    function setActiveTab(id) {
        setActiveTabId(id);
    }


    useEffect(() => {
        if (params.token) {
            setTokenValue(params.token)
        }
    })


    const [selectedClient, setSelectedClient] = useState("Business"); //default value

    function handleSelectChange(event) {
        setSelectedClient(event.target.value);
    }


    const notifyError = (msg) => toast.error(msg);
    const notifySuccess = (msg) => toast.success(msg);

    function passwordReset(values) {

        //alert(tokenValue)
        if (!!values.password) {
            var bodyFormData = new FormData();
            bodyFormData.append('token', tokenValue);
            bodyFormData.append('password', values.password);

            axios({
                method: "post",
                url: server_base_url + "/api/user/password_reset/confirm/",
                data: bodyFormData,
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(function (response) {
                    var obj = response.data;

                    if (obj.non_field_errors) {

                    } else {

                        notifySuccess('A new password has been set successfully!')
                    }

                })
                .catch(function (response) {
                    //handle error

                    if (response.status === 400) {

                        var obj = response.data;

                        if (obj.non_field_errors) {

                            notifyError('Password reset Failed!')
                        }
                    } else {
                        notifyError('Password reset Failed!')
                    }


                });

        } else {
            notifyError('A Token is required!');

        }

    }


    return (

        <div>


            <div id="login-page">


                <div className="container-fluid">
                    <div className="container" style={{transform: "skewY(8deg)", padding: '50px'}}>
                        <div className="row">
                            <div id="login-page-sell" className="col-md-6">
                                <div id="main-slider">
                                    <div className="slide">
                                        <div className="noti-holder">
                                        </div>
                                        <div className="spacer"/>
                                        <img src={logo_2}/>
                                        <br/>
                                        <h4 className="big-title" style={{color: "white", fontSize: '24px'}}>Unlock Better Decisions with Earth Observation & AI</h4>

                                        {/* <p style={{color: "white"}}>Lend Money responsibly</p>
                                        <p style={{color: "white"}}>Make money intelligently</p>
                                        <p style={{color: "white"}}>Save money efficiently</p> */}

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" id="login-page-form">
                                <div id="header-login" style={{display: "flex"}}>
                                    <div align="center" style={{backgroundColor: "white", padding: "25px"}}>
                        <Formik className="form-detail"
                                initialValues={{
                                    password: ''
                                }}
                                onSubmit={async (values) => passwordReset(values)}
                                validationSchema={Yup.object({
                                    // firstName: Yup.string().required('required'),
                                    // lastName: Yup.string().required('required'),
                                    password: Yup.string().required('required')
                                })}
                        >
                            <div className="section" style={{margin: '50px'}}>
                                {/*<h1>Dependent Formik Field Example</h1>*/}
                                <Form>
                                    <label className="form-row-inner">
                                        New password
                                    </label>
                                    <Field name="password" type="password" className="input-text"/>
                                    <ErrorMessage className="error" component="div" name="email"
                                                  style={{color: "red"}}/>
                                    {/*<button type="submit">Login</button>*/}


                                    <div className={classes.formButtons}>
                                        {isLoading ? (
                                            <CircularProgress size={26}
                                                              className={classes.loginLoader}/>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                type="submit"
                                            >
                                                Reset Password
                                            </Button>
                                        )}
                                    </div>


                                </Form>
                            </div>
                        </Formik>
                                        <div style={{margin: '50px'}}>
                                            <a href="#/login">Login / Register?</a>
                                        </div>
                                    </div>


                                    <img id="login-blob" src={blob_two} alt="Creditais Home Page" style={{
                                        width: '300px',
                                        height: '407px',
                                        marginBottom: '-400px',
                                        marginLeft: '200px'
                                    }}/>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div id="footer" className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="address-holder">
                                <div>
                                    <img src={logo_2}/>
                                    <br/>
                                    <br/>
                                </div>
                                <p>
                                    CreditAIs provides access to credit for small businesses through the use of
                                    machine learning applied to alternative data.<br/><br/>
                                </p>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="address-holder">
                                <div style={{color: 'white', fontSize: '20px'}}>Address</div>
                                <br/>
                                <div>
                                    <p>11th Floor, The Bank - Rosebank<br/>Cradock Ave,<br/>Johannesburg,<br/>South
                                        Africa</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="footer-menu-holder">
                                <h4>Navigate</h4>
                                <ul className="footer-menu">
                                    <li><a href="#/">Home</a></li>
                                    <li><a href="#/login">Login / Register </a></li>
                                    <li><a href="#/why-us">Why Us</a></li>
                                    <li><a href="#/product">Our Product Offering</a></li>
                                    <li><a href="#/about">About Us</a></li>
                                    <li><a href="#/contact">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="footer-menu-holder">
                                <h4>Social</h4>
                                <ul className="footer-menu">
                                    <li><a href="https://www.facebook.com/CreditAis-1109869665878153/"
                                           target="_blank">Facebook</a></li>
                                    {/*<li><a href="#/login" target="_blank">Instagram</a></li>*/}
                                    <li><a href="https://twitter.com/creditaisloan" target="_blank">Twitter</a></li>
                                    <li><a href="https://www.linkedin.com/company/creditais/about/"
                                           target="_blank">Linkedin</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*</Grid>*/}
            <ToastContainer/>
        </div>

    );
}

export default withRouter(NewPasswordComponent);
