import React, {Component} from 'react';
import './web_files/css/bootstrap-slider.min.css';
import './web_files/css/slick.css';
import './web_files/css/style.css';
import './web_files/css/custom.css';
import {Nav, Navbar, Container} from 'react-bootstrap';
import mockup from "./web_files/images/mockup.png";
import sponsor_ibm from "./web_files/images/sponsor_ibm.png";
import sponsor_dazzle from "./web_files/images/sponsor_dazzle.png";
import royal_finance from "./web_files/images/royal_finance.png";
import stencil_logo from "./web_files/images/stencil_logo.png";
import logo_2 from "./web_files/images/logo_2.png"
import home_page from "./web_files/images/home_page.png"
import algorithm from "./web_files/images/algorithm.png"
import blob from "./web_files/images/blob.png"
import cash from "./web_files/images/cash.png"
import reduce from "./web_files/images/reduce.png"
import {toast, ToastContainer} from "react-toastify";

class websiteMainpage extends Component {


    constructor(props) {
        super(props);
        this.state = {
            home: true,
            profile: false,
            name: '',
            email: '',
            errors: {},
            my_api_keys: [],
            my_connected_apps: [],
            users: [],
            usersLength: 0,
            message: "",
        };

    }

    notifyError = (msg) => toast.error(msg);

    userRedirect(selector_elem)
    {
       var user_value =document.getElementById(''+selector_elem+'').value;
       // alert(user_value);

        if(user_value)
        {
            if(this.ValidateEmail(user_value))
            {
              window.location.href="#/login?email="+user_value;
            }else{
                this.notifyError('Invalid Email Address')
            }


        }else{
          this.notifyError('Enter your email address')
        }

    }


    ValidateEmail(input) {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        return !!input.match(validRegex);
    }



    render() {
        var api_dashboard =
            <div>
                <div id="header-holder" className="main-header">
                    <div className="bg-animation">
                        <div className="graphic-show">
                            <img className="fix-size" src="images/graphic1.png" alt=""/>
                            <img className="img img1" src="images/graphic1.png" alt=""/>
                            <img className="img img2" src="images/graphic2.png" alt=""/>
                            <img className="img img3" src="images/graphic3.png" alt=""/>
                        </div>
                    </div>

                    <Navbar expand='sm' variant="dark">
                        <Container>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                            <Navbar.Brand href="#home"><img src={logo_2} alt="logo"
                            /></Navbar.Brand>
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav >
                                    <Nav.Link href="#/">Home</Nav.Link>
                                    <Nav.Link href="#/about">About Us</Nav.Link>
                                    <Nav.Link href="#/why-us">Why use it? </Nav.Link>
                                    {/*<Nav.Link href="#/contact">Contact Us </Nav.Link>*/}
                                    {/*<Nav.Link href="#/login"  style={{color:'white', borderStyle: 'solid', borderWidth: 'thin'}}>Login / Sign up </Nav.Link>*/}
                                    {/*<a href="/#/login" className="ybtn ybtn-header-color" style={{color:'#120F7F', backgroundColor:'white'}}>Sign UP</a>*/}
                                </Nav>
                                <Nav className="ml-auto">
                                                       <Nav.Link href="#/contact">Contact Us </Nav.Link>
                                    <Nav.Link href="#/login"  style={{color:'white', borderStyle: 'solid', borderWidth: 'thin', paddingLeft: '5px'}}>Login / Sign up </Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>

                    <div id="top-content" className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div id="main-slider">
                                        <div className="slide">
                                            <div className="noti-holder">
                                            </div>
                                            <div className="spacer"/>
                                            <div className="big-title">Enabling Access to Finance </div>
                                            <p>We help aspiring borrowers get credit whilst digitizing credit application processes for Micro-Lenders to expand their lending universe.
                                            </p>
                                            <div className="btn-holder">
                                                <input className="user_email" placeholder="Email address" id="user_email"/>
                                                <a  className="ybtn ybtn-header-color" onClick={()=> this.userRedirect('user_email')} style={{color:'#120F7F', backgroundColor:'white', cursor: 'pointer'}}>Get started</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" id="mockup-image">
                                    <img src={mockup} alt=""
                                         style={{width: '500px', height: '400px', marginBottom: '-600px'}}/>
                                    {/*<img className="header-graphic" src="images/graphic1.png" alt="" />*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="info" className="container-fluid">

                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div style={{color: '#0B0134', fontSize: '50PX'}}>Our Partnerships </div>
                                <br/>
                                
                                    <div>
                                    <img src={royal_finance} alt=""
                  style={{ width: '294px', height: '120px', marginRight: '50px' }} />
                <img src={stencil_logo} alt=""
                  style={{ width: '220px', height: '83px', marginRight: '50px' }} />
              
                                </div>
                                <br />
                                <p style={{ color: '#0B0134', fontSize: '20PX' }}>Lenders leverage historical client data, advances in machine learning algorithms, and alternative datasets to build a robust credit risk model. This results in more applicants being considered for available funding, an increase in the lending universe for a lender without an increase in non-performing loans and a delightful experience for borrowers.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="services" className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row-title">Financial Inclusion</div>
                                {/*<div className="row-subtitle">Designed to satisfy your creative needs.</div>*/}
                            </div>
                        </div>
                        <div className="row">

                            <div>
                                <p>
                                    More than 200 million formal and informal micro, small and medium-sized enterprises
                                    in emerging markets lack
                                    adequate financing to thrive and grow, the majority cite lack of
                                    collateral and credit history and business informality.
                                </p>

                                <b/>
                                <p>
                                    We provide a solution to this problem through an API that we provide to financial
                                    service providers to
                                    score small and medium-sized businesses whilst maintaining Bayes and information
                                    regulatory compliance.
                                    Our solution provides explainable deep learning models that use alternative data to
                                    accurately score
                                    small businesses that don't fit the incumbent scoring criteria.
                                </p>

                                <br/>
                                <a href="/#/login" className="ybtn ybtn-accent-color ybtn-shadow">Register</a>
                                <br/>
                                <br/>
                                <img src={blob} alt="Creditais Home Page" style={{
                                    width: '300px',
                                    height: '407px',
                                    marginBottom: '-900px',
                                    marginLeft: '-60px'
                                }}/>

                                <div style={{display: 'flex'}} >
{/*                                    <div><img src={algorithm} alt="Creditais Home Page"
                                              style={{width: '271px', height: '362px', marginRight: '150px'}}/></div>*/}
                                    <div id="home-page-image-4"><img src={mockup} alt="Creditais Home Page"
                                              style={{width: '500px', height: '362px', marginRight: '150px'}}/></div>
                                    <div id="home-page-image-3"><img src={home_page} alt="Creditais Home Page"
                                              style={{width: '600px', height: '626px'}}/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="message1" className="container-fluid message-area">
                    <div className="bg-color"/>
                </div>

                <div id="testimonials" className="container-fluid">
                    <div className="bg-color"/>
                    <div className="container">
                    </div>
                </div>
                <div id="more-features" className="container-fluid">
                    <div className="container" style={{transform: "skewY(8deg)"}}>
{/*                        <div className="row">
                            <div className="col-md-12">
                                <div className="row-title" style={{color: "#FFFFFF", fontSize: "50px"}}>Seamless
                                    Integration
                                </div>
                                <div className="row-subtitle">Connect your app in 4 easy steps.</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="row-title" style={{color: "#FFFFFF", fontSize: "20px"}}>1.</div>
                                <div style={{color: 'white', fontSize: '16px'}}>To get the functionality we provide, register
                                    on creditais.com and request activation on our contact page.
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row-title" style={{color: "#FFFFFF", fontSize: "20px"}}>2.</div>
                                <div style={{color: 'white', fontSize: '16px'}}>Request the library from CreditAIs, and
                                    add the library to your
                                    project
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row-title" style={{color: "#FFFFFF", fontSize: "20px"}}>3.</div>
                                <div style={{color: 'white', fontSize: '16px'}}>Configure library with 4 lines of code
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row-title" style={{color: "#FFFFFF", fontSize: "20px"}}>4.</div>
                                <div style={{color: 'white', fontSize: '16px'}}>That’s it, you are done! Visit your
                                    dashboard and get credit scores.
                                </div>
                            </div>
                        </div>
                        <br/>*/}
                        <br/>

                        <div className="row">
                            <div className="col-md-6">
                                <img src={cash} alt="" style={{width: '60px', height: '60px'}}/>
                                <br/>
                                <br/>
                                <div className="row-title" style={{color: "#FFFFFF", fontSize: "20px"}}>Improves Loan
                                    Access
                                </div>
                                <div style={{color: 'white', fontSize: '16px'}}>
                                    Our predictive insights help lenders to enhance their risk decisions,
                                    refine their provision overlay models based on statistically proven
                                    behavioural variables, and most importantly expand their target credit
                                    universe by using non-traditional data to assess risk and predict future
                                    performance on individuals that have no credit track record.
                                </div>
                            </div>
                            <div className="col-md-6">
                                <br/>
                                <img src={reduce} alt="" style={{width: '60px', height: '60px'}}/>
                                <br/>
                                <br/>
                                <div className="row-title" style={{color: "#FFFFFF", fontSize: "20px"}}>Reduce
                                    Non-performing Loans
                                </div>
                                <div style={{color: 'white', fontSize: '16px', justifyContent: 'left'}}>
                                    Alternative credit data provides additional data to enrich decisions across
                                    financial services.
                                    The data drives
                                    greater access to credit for consumers and profitable growth for lenders through
                                    more informed
                                    lending decisions.
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                            <div className="row-title" style={{color: "#FFFFFF", fontSize: "30px", textAlign: "left"}} align="left">
                                Enabling Access to Finance
                                </div>
                        <br/>
                        <br/>
                                                                    <div className="btn-holder"  align="left">
                                                <input className="user_email" placeholder="Email address" id="user_email2"/>
                                                <a  className="ybtn ybtn-header-color" onClick={()=> this.userRedirect('user_email2')} style={{color:'#120F7F', backgroundColor:'white', cursor: 'pointer'}}>Get started</a>
                                            </div>
                    </div>
                </div>
                            <div id="footer" className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="address-holder">
                                <div>
                                    <img src={logo_2}/>
                                    <br/>
                                    <br/>
                                </div>
                                <p>
                                    {/*CreditAIs provides access to credit for small businesses through the use of*/}
                                    {/*machine learning applied to alternative data.*/}
                                    We help aspiring borrowers get credit whilst digitizing credit application processes for Micro-Lenders.
                                    <br/><br/>
                                </p>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="address-holder">
                                <div style={{color: 'white', fontSize: '20px'}}>Address</div>
                                <br/>
                                <div>
                                    <p>11th Floor, The Bank - Rosebank<br/>Cradock Ave,<br/>Johannesburg,<br/>South
                                        Africa</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="footer-menu-holder">
                                <h4>Navigate</h4>
                                <ul className="footer-menu">
                                    <li><a href="#/">Home</a></li>
                                    <li><a href="#/login">Login / Register </a></li>
                                    <li><a href="#/why-us">Why Us</a></li>
                                    <li><a href="#/about">About Us</a></li>
                                    <li><a href="#/contact">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="footer-menu-holder">
                                <h4>Social</h4>
                                <ul className="footer-menu">
                                    <li><a href="https://www.facebook.com/CreditAis-1109869665878153/" target="_blank">Facebook</a></li>
                                    {/*<li><a href="#/login" target="_blank">Instagram</a></li>*/}
                                    <li><a href="https://twitter.com/creditaisloan" target="_blank">Twitter</a></li>
                                    <li><a href="https://www.linkedin.com/company/creditais/about/" target="_blank">Linkedin</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <ToastContainer />
            </div>

        return api_dashboard;

    }
}


export default websiteMainpage;
