import React, { Component } from 'react';

// import './web_files/css/bootstrap.min.css';

import './web_files/css/bootstrap-slider.min.css';
// import './web_files/css/fontawesome-all.min.css';
import './web_files/css/slick.css';
import './web_files/css/style.css';
import './web_files/css/custom.css';
import logo from "./login/logo_v2.svg";
// import axios from "axios";
import { Link, withRouter } from 'react-router-dom';
import { Nav, Navbar, Container } from 'react-bootstrap';
import sponsor_ibm from "./web_files/images/sponsor_ibm.png";
// import pwc from "./web_files/images/pwc.png"
import logo_2 from "./web_files/images/logo_2.png"
// import kyazze from "./web_files/images/kyazze.png"
// import thulisile from "./web_files/images/thulisile.png"
// import sabelo from "./web_files/images/sabelo.png"
// import eva from "./web_files/images/eva.png"
// import vumi from "./web_files/images/vum.png"
import blob from "./web_files/images/blob.png";
// import sponsor_in from "./web_files/images/sponsor_in.png";
// import royal_finance from "./web_files/images/royal_finance.png";
// import stencil_logo from "./web_files/images/stencil_logo.png";
import mfsa_logo from "./web_files/images/mfsa_logo.png";
import sponsor_dazzle from "./web_files/images/sponsor_dazzle.png";

function AboutUsComponent(props) {


  return (
    <div>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0,maximum-scale=1.0, user-scalable=no" />
      <title>CreditAIS</title>
      <div className="inner-header about-header">
        <Navbar expand='sm' variant="dark" sticky="top" className="custom_header">
          <Container>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Brand href="#home"><img src={logo_2} alt="logo"
            /></Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="#/">Home</Nav.Link>
                <Nav.Link href="#/about">About Us</Nav.Link>
                <Nav.Link href="#/why-us">Why use it? </Nav.Link>
                <Nav.Link href="#/product">Our Product Offering </Nav.Link>
                <Nav.Link href="#/contact">Contact Us </Nav.Link>
              </Nav>

            </Navbar.Collapse>
          </Container>
        </Navbar>
        <img id="about_blob_one" src={blob} alt="Creditais Home Page" style={{
          width: '300px',
          height: '407px',
          marginBottom: '-700px',
          marginRight: '60px'
        }} />
        <div id="page-head" className="container-fluid inner-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12 company-info-holder" align="center">
                <div align="center">
                  <h4 style={{ color: '#0B0134', fontSize: '36PX' }}>Partner with us to make data-driven decisions that drive success.</h4>
                </div>
                <div className="info-slider">
                  <div>
                    <div style={{ color: '#0B0134', fontSize: '20PX' }} align="center">
                      <p>
                      CreditAIs is a South African company specializing in Earth Observation and AI-driven business solutions. Our technology delivers measurable impact in agriculture, infrastructure planning, environmental compliance, and operational efficiency.  
                        .<br />
                        <br />
                        We prioritize rapid implementation and verified ROI—helping organizations turn advanced technology into bottom-line results.
                                            </p>
                                            <br />
                        <br />
                        <a href="/#/login" className="ybtn ybtn-accent-color ybtn-shadow">Get started</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
{/*      <div id="team" className="container-fluid">*/}

{/*        <div className="container">*/}
{/*          <div className="row">*/}
{/*            <div className="col-md-12">*/}
{/*              <div className="row-title">We Have Some Awesome People.</div>*/}
{/*              <div className="row-subtitle" />*/}
{/*            </div>*/}
{/*          </div>*/}
{/*          <div className="row team-holder">*/}
{/*            <div className="col-sm-6 col-md-4" style={{ marginBottom: '15px' }}>*/}
{/*              <div className="container-cred">*/}
{/*                <div className="inner-area">*/}
{/*                  <img src={sabelo} alt=""*/}
{/*                    style={{*/}
{/*                      width: '180px',*/}
{/*                      height: '167px',*/}
{/*                      // marginRight: '50px'*/}
{/*                    }} alt="" />*/}
{/*                </div>*/}

{/*                <div className="name">Sabelo Sibanda</div>*/}
{/*                <div className="about">CEO</div>*/}
{/*              </div>*/}
{/*            </div>*/}
{/*            <div className="col-sm-6 col-md-4" style={{ backgroundColor: 'white', marginBottom: '15px' }}>*/}
{/*              <div className="container-cred">*/}
{/*                <div className="inner-area">*/}
{/*                  <img src={thulisile} alt=""*/}
{/*                    style={{*/}
{/*                      width: '180px',*/}
{/*                      height: '167px',*/}
{/*                      // marginRight: '50px'*/}
{/*                    }} alt="" />*/}
{/*                </div>*/}

{/*                <div className="name">Thulisile Volwana</div>*/}
{/*                <div className="about">COO</div>*/}
{/*              </div>*/}
{/*            </div>*/}
{/*            <div className="col-sm-6 col-md-4" style={{ marginBottom: '15px' }}>*/}
{/*              <div className="container-cred">*/}
{/*                <div className="inner-area">*/}
{/*                  <img src={kyazze} alt=""*/}
{/*                    style={{*/}
{/*                      width: '180px',*/}
{/*                      height: '167px',*/}
{/*                      // marginRight: '50px'*/}
{/*                    }} alt="" />*/}
{/*                </div>*/}

{/*                <div className="name">Dr. Michael Kyazze</div>*/}
{/*                <div className="about">CTO</div>*/}
{/*              </div>*/}
{/*            </div>*/}
{/*            <div className="col-sm-6 col-md-4" style={{ marginBottom: '15px' }}>*/}
{/*              <div className="container-cred">*/}
{/*                <div className="inner-area">*/}
{/*                  <img src={eva} alt=""*/}
{/*                    style={{*/}
{/*                      width: '180px',*/}
{/*                      height: '167px',*/}
{/*                      // marginRight: '50px'*/}
{/*                    }} alt="" />*/}
{/*                </div>*/}

{/*                <div className="name">Eva Ssozi</div>*/}
{/*                <div className="about">ML Engineer</div>*/}
{/*              </div>*/}
{/*            </div>*/}
{/*/!*            <div className="col-sm-6 col-md-4" style={{ marginBottom: '15px' }}>*/}
{/*              <div className="container-cred">*/}
{/*                <div className="inner-area">*/}
{/*                  <img src={vumi} alt=""*/}
{/*                    style={{*/}
{/*                      width: '180px',*/}
{/*                      height: '167px',*/}
{/*                      // marginRight: '50px'*/}
{/*                    }} alt="" />*/}
{/*                </div>*/}

{/*                <div className="name">Vumile Sibande </div>*/}
{/*                <div className="about">Javascript Developer</div>*/}
{/*              </div>*/}
{/*            </div>*!/*/}
{/*          </div>*/}
{/*          */}
{/*        </div>*/}
{/*      </div>*/}
      <div id="info" className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div style={{ color: '#0B0134', fontSize: '50PX' }}>Our Investors </div>
              <br />
              <div>
              <img src={sponsor_ibm} alt=""
                                         style={{width: '294px', height: '120px', marginRight: '50px'}}/>
                                    <img src={sponsor_dazzle} alt=""
                                         style={{width: '294px', height: '120px', marginRight: '50px'}}/>
              </div>

              <br />
              {/* <p style={{ color: '#0B0134', fontSize: '20PX' }}>Lenders leverage historical client data, advances in machine learning algorithms, and alternative datasets to build a robust credit risk model. This results in more applicants being considered for available funding, an increase in the lending universe for a lender without an increase in non-performing loans and a delightful experience for borrowers.</p> */}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-12">
              <div style={{ color: '#0B0134', fontSize: '50PX' }}>Membership</div>
              <div>
                <img src={mfsa_logo} alt=""
                  style={{ width: '294px', height: '120px', marginRight: '50px' }} />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div id="footer" className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-xs-6 col-sm-3 col-md-3">
              <div className="address-holder">
                <div>
                  <img src={logo_2} />
                  <br />
                  <br />
                </div>
                <p>
                  Explore how you can use satellite data and artificial intelligence to make better decisions in agriculture, infrastructure, environmental monitoring, and business.
                  {/*CreditAIs provides access to credit for small businesses through the use of*/}
                  {/*                  machine learning applied to alternative data.*/}
                  <br /><br />

                </p>
              </div>
            </div>
            <div className="col-xs-6 col-sm-3 col-md-3">
              <div className="address-holder">
                <div style={{ color: 'white', fontSize: '20px' }}>Address</div>
                <br />
                <div>
                  <p>11th Floor, The Bank - Rosebank<br />Cradock Ave,<br />Johannesburg,<br />South
                                        Africa</p>
                </div>
              </div>
            </div>
            <div className="col-xs-6 col-sm-3 col-md-3">
              <div className="footer-menu-holder">
                <h4>Navigate</h4>
                <ul className="footer-menu">
                  <li><a href="#/">Home</a></li>
                  <li><a href="#/login">Login / Register </a></li>
                  <li><a href="#/why-us">Why Us</a></li>
                  <li><a href="#/product">Our Product Offering</a></li>
                  <li><a href="#/about">About Us</a></li>
                  <li><a href="#/contact">Contact Us</a></li>
                </ul>
              </div>
            </div>
            <div className="col-xs-6 col-sm-3 col-md-3">
              <div className="footer-menu-holder">
                <h4>Social</h4>
                <ul className="footer-menu">
                  <li><a href="https://www.facebook.com/CreditAis-1109869665878153/"
                    target="_blank">Facebook</a></li>
                  {/*<li><a href="#/login" target="_blank">Instagram</a></li>*/}
                  <li><a href="https://twitter.com/creditaisloan" target="_blank">Twitter</a></li>
                  <li><a href="https://www.linkedin.com/company/creditais/about/"
                    target="_blank">Linkedin</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );


}


export default withRouter(AboutUsComponent);
// export default AboutUsComponent;
