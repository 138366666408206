import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { logoutUser, registerAPI , deleteKey, getConnections} from '../../redux/actions/authActions';
import './api.css';
import axios from "axios";
// import classnames from 'classnames';
// import { registerAPI } from '../../redux/actions/authActions';
// import  BulletList  from 'react-content-loader';
// import { Link, withRouter } from 'react-router-dom';
// const MyBulletListLoader = () => <BulletList />
// import setAuthToken from '../../utils/setAuthToken';
// import { confirmAlert } from 'react-confirm-alert';
import {mikyaLoginUser} from "../../context/UserContext";
import {Grid} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Widget from "../../components/Widget";
import Table from "./components/Table/Table";
// import mock from "./mock";
import mock from "../dashboard/mock";
import {makeStyles} from "@material-ui/styles";
import PageTitle from "../../components/PageTitle";
import Select from "react-select"; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// const constants = {
//   "url": "http://localhost:8000/api/credential/?format=json"
// }
import {server_base_url} from "../../context/UserContext";
// import mock from "../dashboard/mock";

// const datatableData = [
//   ["wgrtdjhgdaQDDSAWXF", "Android", "Active", "2-05-2019"],
// ];

const datatableData = [
];

  const options = [
  { value: 'Business', label: 'App Platfrom Summary Report' },
  { value: 'Individual', label: 'Credit Score Report' }
]

const constants = {
  "url": server_base_url+"/api/credential/?format=json"
}
const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

class ReportComponent extends Component {


  constructor(props) {
    super(props);
    this.state = {
        home: true,
        profile: false,

        name: '',
        email: '',
        errors: {},
        my_api_keys: [],
        my_connected_apps: [],

      users: [],
      usersLength: 0,
      message: "",
    };


}



  render() {
   var api_dashboard =      <>
      <PageTitle title="Credit Applications" />

      <Grid container spacing={4}>

        <Grid item xs={12}>
            {/*<Select options={options} placeholder="Select Report"/>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            <br/>
          <MUIDataTable
            title="Applications"
            data={datatableData}
            columns={["Name", "Age", "Occupation", "Application details"]}
            options={{
              filterType: "checkbox",
            }}
          />
        </Grid>
      </Grid>
    </>

return api_dashboard

  }
}



export default ReportComponent;
