import React, {Component} from 'react';
import './web_files/css/bootstrap-slider.min.css';
import './web_files/css/slick.css';
import './web_files/css/style.css';
import './web_files/css/custom.css';
import {Link, withRouter} from 'react-router-dom';
import {Nav, Navbar, Container} from 'react-bootstrap';
import logo_2 from "./web_files/images/logo_2.png"
import ninety_five_percent from "./web_files/images/95.png"
import why_us from "./web_files/images/why_us.png"
import ninety_percent from "./web_files/images/90.png"
import hundred_percent from "./web_files/images/100.png"
import blob_two from "./web_files/images/blob_two.png";

function WhyUsComponent(props) {


    return (
        <div>
            <meta charSet="UTF-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0,maximum-scale=1.0, user-scalable=no"/>
            <title>CreditAIS</title>
            <div className="inner-header about-header">
                <Navbar expand='sm' variant="dark" sticky="top" className="custom_header">
                    <Container>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Brand href="#home"><img src={logo_2} alt="logo"
                        /></Navbar.Brand>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link href="#/">Home</Nav.Link>
                                <Nav.Link href="#/about">About Us</Nav.Link>
                                <Nav.Link href="#/why-us">Why use it? </Nav.Link>
                                <Nav.Link href="#/product">Our Product Offering</Nav.Link>
                                <Nav.Link href="#/contact">Contact Us </Nav.Link>
                                {/*<Nav.Link onClick={onClick} >*/}
                                {/*    Test*/}
                                {/*</Nav.Link>*/}

                            </Nav>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <div id="page-head" className="container-fluid inner-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company-info-holder" style={{display: 'flex'}}>

                                <div className="col-sm-4 col-md-4" id="why-us-one">
                                    <h4 style={{color: '#0B0134', fontSize: '26PX'}}>Why would you use CreditAIS</h4>
                                </div>
                                <div className="col-sm-8 col-md-8">
                                    <div style={{color: '#0B0134', fontSize: '20PX'}}>
                                    Start using Creditais today to harness the power of Earth Observation data and AI for better business decisions.
                                    <br />
                                    <br />
                                    <a href="/#/login" className="ybtn ybtn-accent-color ybtn-shadow text-center">Get started</a>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <div className="col-md-12 company-info-holder" style={{display: 'flex', marginTop: '50px', justifyContent: 'space-between'}}>
                            <div className="col-sm-4 col-md-4" style={{
                                backgroundColor: '#120F7F',
                                marginRight: '20px',
                                color: 'white',
                                fontSize: '18px',
                                padding: '20px 25px',
                                borderRadius: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '32%'
                            }} id="why-us-two">
                                <h3 style={{fontWeight: 'bold', marginBottom: '15px'}}>For Government Agencies</h3>
                                <ul style={{
                                    listStyleType: 'disc',
                                    paddingLeft: '20px',
                                    margin: '0'
                                }}>
                                    <li>Monitor infrastructure development</li>
                                    <li>Track urban expansion</li>
                                    <li>Manage disaster response</li>
                                    <li>Automate public services</li>
                                </ul>
                            </div>
                            <div className="col-sm-4 col-md-4" id="why-us-three" style={{
                                backgroundColor: '#120F7F',
                                marginRight: '20px',
                                color: 'white',
                                fontSize: '18px',
                                padding: '20px 25px',
                                borderRadius: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '32%'
                            }}>
                                <h3 style={{fontWeight: 'bold', marginBottom: '15px'}}>For Businesses</h3>
                                <ul style={{
                                    listStyleType: 'disc',
                                    paddingLeft: '20px',
                                    margin: '0'
                                }}>
                                    <li>Optimize operations with AI</li>
                                    <li>Ensure regulatory compliance</li>
                                    <li>Streamline procurement</li>
                                    <li>Enhance customer service</li>
                                </ul>
                            </div>
                            <div className="col-sm-4 col-md-4" id="why-us-four" style={{
                                backgroundColor: '#120F7F',
                                marginRight: '0px',
                                color: 'white',
                                fontSize: '18px',
                                padding: '20px 25px',
                                borderRadius: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                width: '32%'
                            }}>
                                <h3 style={{fontWeight: 'bold', marginBottom: '15px'}}>For Environmental Organizations</h3>
                                <ul style={{
                                    listStyleType: 'disc',
                                    paddingLeft: '20px',
                                    margin: '0'
                                }}>
                                    <li>Monitor conservation efforts</li>
                                    <li>Validate carbon credits</li>
                                    <li>Track environmental changes</li>
                                    <li>Ensure ESG compliance</li>
                                </ul>
                            </div>
                        </div>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
            <div id="team" className="container-fluid">
                <div className="container">
                    <div align="left">
                        <h4 style={{color: '#0B0134', fontSize: '26PX'}}>
                            Technical Capabilities.
                        </h4>
                    </div>


                    <div id="about_us_desktop" className="col-md-12 company-info-holder">

                        <div className="col-sm-6 col-md-6" style={{display: 'flex', marginTop: '100px'}}>


                            <div className="col-sm-3 col-md-3">
                               <span>
                           <img src={hundred_percent} alt=""
                                style={{width: '90px', height: '90px', marginRight: '5px'}}/>
                                <p>Compliant: Built to meet regulatory requirements
                                </p>
                            </span>
                            </div>

                            {/* <div className="col-sm-3 col-md-3">
                                             <span>
                                <img src={ninety_five_percent} alt=""
                                     style={{width: '90px', height: '90px', marginRight: '5px'}}/><br/>
                                <p>Cyber Security: Enterprise-grade security protocols</p>
                            </span>
                            </div> */}


                            <div className="col-sm-3 col-md-3">
                                                            <span>
                            <img src={hundred_percent} alt=""
                                 style={{width: '90px', height: '90px', marginRight: '5px'}}/><br/>
                                <p>Cyber Security: Enterprise-grade security protocols</p>
                      </span>
                            </div>

                            <div className="col-sm-3 col-md-3">
                                      <span>
                           <img src={ninety_percent} alt=""
                                style={{width: '90px', height: '90px', marginRight: '5px'}}/><br/>
                                <p>AI, ML, and Data Science: Cutting-edge technology powering every solution</p>
                      </span>
                            </div>


                        </div>
                        {/*<div className="info-slider">*/}
                        <div className="col-sm-6 col-md-6" id="why-us-six">
                            <div style={{color: '#0B0134', fontSize: '20PX'}}>
                                <img src={why_us} alt=""
                                     style={{width: '570px', height: '329px', marginRight: '50px'}}/>
                            </div>
                        </div>
                        {/*</div>*/}

                        <img src={blob_two} alt="Creditais Home Page" style={{
                            width: '300px',
                            height: '407px',
                            marginBottom: '-900px',
                            marginLeft: '-60px'
                        }}/>

                    </div>


                    <div id="about_us_mobile" className="col-md-12 company-info-holder" align="center">



                                   <span>
                           <img src={hundred_percent} alt=""
                                style={{width: '90px', height: '90px', marginRight: '5px'}}/>
                                <p>Compliant</p>
                            </span>

                        <span>
                                <img src={ninety_five_percent} alt=""
                                     style={{width: '90px', height: '90px', marginRight: '5px'}}/><br/>
                                <p>Credit Risk</p>
                            </span>


                        <span>
                            <img src={hundred_percent} alt=""
                                 style={{width: '90px', height: '90px', marginRight: '5px'}}/><br/>
                                <p>Cyber Security</p>
                      </span>

                        <span>
                           <img src={ninety_percent} alt=""
                                style={{width: '90px', height: '90px', marginRight: '5px'}}/><br/>
                                <p>AI, ML, and Data Science</p>
                      </span>


                        {/*</div>*/}

                        <img src={blob_two} alt="Creditais Home Page" style={{
                            width: '300px',
                            height: '407px',
                            marginBottom: '-900px',
                            marginLeft: '-60px'
                        }}/>

                    </div>


                </div>
            </div>
            
            <div id="footer" className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="address-holder">
                                <div>
                                    <img src={logo_2}/>
                                    <br/>
                                    <br/>
                                </div>
                                <p>
{/*                                    CreditAIs provides access to credit for small businesses through the use of
                                    machine learning applied to alternative data.*/}
                                    Explore how you can use satellite data and artificial intelligence to make better decisions in agriculture, infrastructure, environmental monitoring, and business.
                                    <br/><br/>
                                </p>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="address-holder">
                                <div style={{color: 'white', fontSize: '20px'}}>Address</div>
                                <br/>
                                <div>
                                    <p>11th Floor, The Bank - Rosebank<br/>Cradock Ave,<br/>Johannesburg,<br/>South
                                        Africa</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="footer-menu-holder">
                                <h4>Navigate</h4>
                                <ul className="footer-menu">
                                    <li><a href="#/">Home</a></li>
                                    <li><a href="#/login">Login / Register </a></li>
                                    <li><a href="#/why-us">Why Us</a></li>
                                    <li><a href="#/product">Our Product Offering</a></li>
                                    <li><a href="#/about">About Us</a></li>
                                    <li><a href="#/contact">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="footer-menu-holder">
                                <h4>Social</h4>
                                <ul className="footer-menu">
                                    <li><a href="https://www.facebook.com/CreditAis-1109869665878153/"
                                           target="_blank">Facebook</a></li>
                                    {/*<li><a href="#/login" target="_blank">Instagram</a></li>*/}
                                    <li><a href="https://twitter.com/creditaisloan" target="_blank">Twitter</a></li>
                                    <li><a href="https://www.linkedin.com/company/creditais/about/"
                                           target="_blank">Linkedin</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


}


export default withRouter(WhyUsComponent);
// export default AboutUsComponent;
