import React, {useState, useEffect} from "react";
import {
    Grid,
    CircularProgress,
    Typography,
    Button,
    Tabs,
    Tab,
    TextField,
    Fade,
} from "@material-ui/core";
import {withRouter} from "react-router-dom";

// styles
import useStyles from "./styles";
// context
import {useUserDispatch, loginUser, mikyaLoginUser, server_base_url} from "../../context/UserContext";
import Select from 'react-select';
import axios from "axios";
import {Container, Nav, Navbar} from "react-bootstrap";
import logo_2 from "../web_files/images/logo_2.png";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mockup from "../web_files/images/mockup.png";
import blob_two from "../web_files/images/blob_two.png";


function Login(props) {
    var classes = useStyles();

    //const { addToast } = useToasts();
    // global
    var userDispatch = useUserDispatch();

    // local
    var [isLoading, setIsLoading] = useState(false);
    var [error, setError] = useState(null);
    var [activeTabId, setActiveTabId] = useState(1);
    var [firstNameValue, setFirstNameValue] = useState("");
    var [loginValue, setLoginValue] = useState();
    var [passwordValue, setPasswordValue] = useState();
    var [lastNameValue, setLastNameValue] = useState("");
    var [companyValue, setCompanyValue] = useState("");

    var [show_error_modal, setModalValue] = useState(false);
    //

    const urlSearchParams = new URLSearchParams(props.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let set_user = false;

    if (params.email) {
        // document.getElementById('email').value  = params.email;
        // setActiveTabId(1);
        // setLoginValue(params.email);
    }


    useEffect(() => {
        if (params.email) {

                   document.getElementById('email').value  = params.email;
        // setActiveTabId(1);
        // setLoginValue(params.email);
            //setActiveTabId(1);
            setLoginValue(params.email);
        }

    })

    //this.setState({ message: null, error:false })

    const options = [
        {value: 'Business', label: 'A Business'}
        //{value: 'Individual', label: 'An Individual'}
    ]

    function simpleTest(val) {
        setModalValue(val);
        //show_error_modal = val;
    }

    const notifyError = (msg) => toast.error(msg);
    const notifySuccess = (msg) => toast.success(msg);

    function userLogin(loginValue, passwordValue) {


        if (!!loginValue && !!passwordValue) {
            //const {name, email, message} = this.state;
            const conf = {
                'email': loginValue,
                'password': passwordValue
            };

            var bodyFormData = new FormData();
            bodyFormData.append('email', loginValue);
            bodyFormData.append('password', passwordValue);

            axios({
                method: "post",
                url: server_base_url + "/api/login/",
                data: bodyFormData,
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(function (response) {
                    //handle success
                    // console.log(response);


                    var obj = response.data;

                    if (obj.error) {
                        // console.error(obj.message);
                        //show_error_modal = true;
                        //simpleTest(true)

                        // setModalValue(true);

                        // notifyError(obj.message);


                        alert(obj.message);

                        // addToast(obj.message, {
                        //     appearance: 'error',
                        //     autoDismiss: true,
                        // });


                        // toast.error(obj.message, {
                        //     position: "top-center",
                        //     autoClose: 5000,
                        //     hideProgressBar: false,
                        //     closeOnClick: true,
                        //     pauseOnHover: true,
                        //     draggable: true,
                        //     progress: undefined,
                        // });

                        mikyaLoginUser(
                            userDispatch,
                            null,
                            null,
                            props.history,
                            setIsLoading,
                            setError,
                        )
                    } else {
                        mikyaLoginUser(
                            userDispatch,
                            loginValue,
                            passwordValue,
                            props.history,
                            setIsLoading,
                            setError,
                        )

                        localStorage.setItem('verified_status', obj.verified_status);
                        localStorage.setItem('is_super_super', obj.is_super_super);
                    }

                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });

        } else {
            //alert('Complete all form fields!');
            notifyError('Complete all form fields!');

            // addToast('Complete all form fields!', {
            //     appearance: 'error',
            //     autoDismiss: true,
            // });

            // toast.error('Complete all form fields!', {
            //     position: "top-center",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
        }

    }

    function ServerMessage(props) {

        // if(this.state.error)
        // {
        //                 if(props.error)
        //     {
        //          return <div className="alert alert-danger" role="alert">
        //             props.message
        //             </div>;
        //     }else{
        //          return   <div className="alert alert-success" role="alert">
        //                 props.message
        //             </div>;
        //     }
        // }else{
        //     return <div>empty</div>
        // }


        //const isLoggedIn = props.isLoggedIn;
        // if (this.state.server_message) {
        //     return <div className="alert alert-danger" role="alert">
        //         {message}
        //         </div>;
        // }
        // return   <div className="alert alert-success" role="alert">
        //             {message}
        //         </div>;
    }

    // function serverResponse(message, type)
    // {
    //     this.setState({ message: message, type:type })
    // }

    //const notifyError = (msg) => toast.error(msg);

    function userRegister(loginValue, passwordValue, firstNameValue, lastNameValue) {

        if (!!loginValue && !!passwordValue && !!firstNameValue && !!lastNameValue) {
            var bodyFormData = new FormData();
            bodyFormData.append('email', loginValue);
            bodyFormData.append('password', passwordValue);
            bodyFormData.append('first_name', firstNameValue);
            bodyFormData.append('last_name', lastNameValue);

            axios({
                method: "post",
                url: server_base_url + "/api/register/",
                data: bodyFormData,
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(function (response) {
                    //handle success
                    //console.log(response);


                    var obj = response.data;

                    if (obj.error) {
                        console.error(obj.message);

                        //alert(obj.message);

                        // addToast(obj.message, {
                        //     appearance: 'error',
                        //     autoDismiss: true,
                        // });
                        // toast.error(obj.message, {
                        //      position: "top-center",
                        //      autoClose: 5000,
                        //      hideProgressBar: false,
                        //      closeOnClick: true,
                        //      pauseOnHover: true,
                        //      draggable: true,
                        //      progress: undefined,
                        //  });

                    } else {

                        //serverResponse(obj.message, 'success');
                        //alert(obj.message);

                        alert('You have been registered, proceed to login');

                        // addToast('You have been registered, proceed to login', {
                        //     appearance: 'error',
                        //     autoDismiss: true,
                        // });

                        // toast.success('You have been registered, proceed to login', {
                        //     position: "top-center",
                        //     autoClose: 5000,
                        //     hideProgressBar: false,
                        //     closeOnClick: true,
                        //     pauseOnHover: true,
                        //     draggable: true,
                        //     progress: undefined,
                        // });

                    }

                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                });
        } else {
            //alert('Complete all form fields!');

            notifyError('Complete all user registration fields!')

            // addToast('Complete all form fields!', {
            //     appearance: 'error',
            //     autoDismiss: true,
            // });
        }

    }


    return (

        <div>



                            <div id="login-page" >


                    <div className="container-fluid" >
                        <div className="container" style={{transform: "skewY(8deg)", padding:'50px'}}>
                            <div className="row">
                                <div id="login-page-sell" className="col-md-6">
                                    <div id="main-slider">
                                        <div className="slide">
                                            <div className="noti-holder">
                                            </div>
                                            <div className="spacer"/>
                                            <img src={logo_2}/>
                                            <br/>
                                            <h4 className="big-title" style={{color:"white", fontSize:'24px'}}>Enabling Access to Finance </h4>

                                            <p style={{color:"white"}}>Lend Money responsibly</p>
                                            <p style={{color:"white"}}>Make money intelligently</p>
                                            <p style={{color:"white"}}>Save money efficiently</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" id="login-page-form">
                                               <div id="header-login" style={{display:"flex"}}>
                <div align="center" style={{backgroundColor:"white", padding:"25px"}}>
                    <div className={classes.form}>
                        <Tabs
                            value={activeTabId}
                            onChange={(e, id) => setActiveTabId(id)}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Login" classes={{root: classes.tab}}/>
                            <Tab label="Register" classes={{root: classes.tab}}/>
                        </Tabs>
                        {activeTabId === 0 && (
                            <React.Fragment>
                                <Fade in={error}>
                                    <Typography color="secondary" className={classes.errorMessage}>
                                        Something is wrong with your login or password :(
                                    </Typography>
                                </Fade>
                                {show_error_modal === true && (<div className="alert alert-danger" role="alert">
                                    An Error has Occurred!
                                </div>)}
                                <TextField
                                    id="email"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={loginValue}
                                    onChange={e => setLoginValue(e.target.value)}
                                    margin="normal"
                                    placeholder="Email Adress"
                                    type="email"
                                    fullWidth
                                />
                                <TextField
                                    id="password"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={passwordValue}
                                    onChange={e => setPasswordValue(e.target.value)}
                                    margin="normal"
                                    placeholder="Password"
                                    type="password"
                                    fullWidth
                                />
                                <div className={classes.formButtons}>
                                    {isLoading ? (
                                        <CircularProgress size={26} className={classes.loginLoader}/>
                                    ) : (
                                        <Button
                                            onClick={() =>
                                                userLogin(loginValue, passwordValue)
                                            }
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                        >
                                            Login
                                        </Button>
                                    )}
                                </div>
                                     <div className={classes.formButtons}>
                                                        <a href="#/reset">Forgot Password?</a>
                                                    </div>
                            </React.Fragment>
                        )}
                        {activeTabId === 1 && (
                            <React.Fragment>
                                {/*<Typography variant="h1" className={classes.greeting}>*/}
                                {/*  Welcome!*/}
                                {/*</Typography>*/}
                                <Typography variant="h2" className={classes.subGreeting}>
                                    Create your business account
                                </Typography>
                                <Fade in={error}>
                                    <Typography color="secondary" className={classes.errorMessage}>
                                        Something is wrong with your login or password :(
                                    </Typography>
                                </Fade>
                                {/*<Select options={options} placeholder="Register As"/>*/}
                                <TextField
                                    id="first_name"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={firstNameValue}
                                    onChange={e => setFirstNameValue(e.target.value)}
                                    margin="normal"
                                    placeholder="First Name"
                                    type="text"
                                    fullWidth
                                />
                                <TextField
                                    id="last_name"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={lastNameValue}
                                    onChange={e => setLastNameValue(e.target.value)}
                                    margin="normal"
                                    placeholder="Last Name"
                                    type="text"
                                    fullWidth
                                />
                                <TextField
                                    id="email"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={loginValue}
                                    onChange={e => setLoginValue(e.target.value)}
                                    margin="normal"
                                    placeholder="Email Address"
                                    type="email"
                                    fullWidth
                                />
                                <TextField
                                    id="password"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={passwordValue}
                                    onChange={e => setPasswordValue(e.target.value)}
                                    margin="normal"
                                    placeholder="Password"
                                    type="password"
                                    fullWidth
                                />
                                 <TextField
                                    id="company"
                                    InputProps={{
                                        classes: {
                                            underline: classes.textFieldUnderline,
                                            input: classes.textField,
                                        },
                                    }}
                                    value={companyValue}
                                    onChange={e => setCompanyValue(e.target.value)}
                                    margin="normal"
                                    placeholder="Company Name"
                                    type="text"
                                    fullWidth
                                />
                                <div className={classes.creatingButtonContainer}>
                                    {isLoading ? (
                                        <CircularProgress size={26}/>
                                    ) : (
                                        <Button
                                            onClick={() =>
                                                userRegister(
                                                    loginValue, passwordValue, firstNameValue, lastNameValue
                                                )
                                            }
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={classes.createAccountButton}
                                        >
                                            Create your account
                                        </Button>
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>


                                                                                    <img id="login-blob" src={blob_two} alt="Creditais Home Page" style={{
                            width: '300px',
                            height: '407px',
                            marginBottom: '-400px',
                            marginLeft: '200px'
                        }}/>


            </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            {/*<Navbar expand='sm' variant="dark" sticky="top" style={{marginTop: '0px'}} className="custom_header">*/}
            {/*    <Container>*/}
            {/*        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>*/}
            {/*        <Navbar.Brand href="#home"><img src={logo_2} alt="logo"*/}
            {/*        /></Navbar.Brand>*/}
            {/*        <Navbar.Collapse id="responsive-navbar-nav">*/}
            {/*            <Nav className="ml-auto">*/}
            {/*                <Nav.Link href="#/">Home</Nav.Link>*/}
            {/*                <Nav.Link href="#/about">About Us</Nav.Link>*/}
            {/*                <Nav.Link href="#/why-us">Why use it? </Nav.Link>*/}
            {/*                <Nav.Link href="#/contact">Contact Us </Nav.Link>*/}

            {/*            </Nav>*/}

            {/*        </Navbar.Collapse>*/}
            {/*    </Container>*/}
            {/*</Navbar>*/}
            {/*<Grid container className={classes.container}>*/}



                <div id="footer" className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-6 col-sm-3 col-md-3">
                                <div className="address-holder">
                                    <div>
                                        <img src={logo_2}/>
                                        <br/>
                                        <br/>
                                    </div>
                                    <p>
                               {/*         CreditAIs provides access to credit for small businesses through the use of
                                        machine learning applied to alternative data.*/}
                                        We help aspiring borrowers get credit whilst digitizing credit application processes for Micro-Lenders.
                                        <br/><br/>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-3">
                                <div className="address-holder">
                                    <div style={{color: 'white', fontSize: '20px'}}>Address</div>
                                    <br/>
                                    <div>
                                         <p>11th Floor, The Bank - Rosebank<br/>Cradock Ave,<br/>Johannesburg,<br/>South
                                        Africa</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-3">
                                <div className="footer-menu-holder">
                                    <h4>Navigate</h4>
                                    <ul className="footer-menu">
                                        <li><a href="#/">Home</a></li>
                                        <li><a href="#/login">Login / Register </a></li>
                                        <li><a href="#/why-us">Why Us</a></li>
                                        <li><a href="#/about">About Us</a></li>
                                        <li><a href="#/contact">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-3">
                                <div className="footer-menu-holder">
                                    <h4>Social</h4>
                                    <ul className="footer-menu">
                                        <li><a href="https://www.facebook.com/CreditAis-1109869665878153/"
                                               target="_blank">Facebook</a></li>
                                        {/*<li><a href="#/login" target="_blank">Instagram</a></li>*/}
                                        <li><a href="https://twitter.com/creditaisloan" target="_blank">Twitter</a></li>
                                        <li><a href="https://www.linkedin.com/company/creditais/about/"
                                               target="_blank">Linkedin</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/*</Grid>*/}
            <ToastContainer/>
        </div>

    );
}

export default withRouter(Login);
