import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import BusinessDashboard from "../../pages/dashboard/BusinessDashboard";
//import APIKeyCreation from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";


// context
import { useLayoutState } from "../../context/LayoutContext";
import APIKeyCreation from "../../pages/dashboard/APIKeyCreation";
import AccountComponent from "../../pages/dashboard/AccountComponent";
import ReportComponent from "../../pages/dashboard/ReportComponent";
import DevDocs from "../../pages/dashboard/DevDocs";
import websiteMainpage  from "../../pages/websiteMainpage";
import AdminUsersComponent from "../../pages/dashboard/AdminUsersComponent";
import OnboardComponent from "../../pages/dashboard/OnboardComponent";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var verified = localStorage.getItem('verified_status');

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          {verified && verified === "False" ? (<></>) : (
              <Sidebar id="app_side_bar"/>
          )}

          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={BusinessDashboard} />
              <Route path="/app/typography" component={Typography} />
              <Route path="/app/tables" component={Tables} />
              <Route path="/app/report" component={ReportComponent} />
              <Route path="/app/notifications" component={Notifications} />
              <Route path="/app/dev" component={APIKeyCreation} />
              <Route path="/app/account" component={AccountComponent} />
              <Route path="/app/documentation" component={DevDocs} />
              <Route path="/app/website" component={websiteMainpage} />
              <Route path="/app/onboard" component={OnboardComponent} />
              <Route path="/app/admin" component={AdminUsersComponent}/>
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              <Route path="/app/ui/maps" component={Maps} />
              <Route path="/app/ui/icons" component={Icons} />
              <Route path="/app/ui/charts" component={Charts} />
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              {/* <div>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Flatlogic
                </Link>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/about'}
                  target={'_blank'}
                  className={classes.link}
                >
                  About Us
                </Link>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/blog'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Blog
                </Link>
              </div> */}
              {/* <div>
                <Link
                  href={'https://www.facebook.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="facebook">
                    <Icon
                      path={FacebookIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://twitter.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://github.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton
                    aria-label="github"
                    style={{marginRight: -12}}
                  >
                    <Icon
                      path={GithubIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
              </div> */}
            </Box>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
