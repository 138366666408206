import React, {Component} from 'react';
import './api.css';
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import PageTitle from "../../components/PageTitle";
import {server_base_url} from "../../context/UserContext";
import AboutUsComponent from "../AboutUsComponent";
import WhyUsComponent from "../WhyUsComponent";
import ContactUsComponent from "../ContactUsComponent";
import abc from "react-multistep";
import {InlineWidget} from "react-calendly";
import OnboardFormComponent from "../dashboard/OnboardFormComponent";

const constants = {
    "url": server_base_url + "/api/credential/?format=json"
}
const useStyles = makeStyles(theme => ({
    tableOverflow: {
        overflow: 'auto'
    }
}))
// const steps = [
//               {name: 'Step One', component: <OnboardFormComponent/>},
//               {name: 'Schedule Call', component: <InlineWidget url="https://calendly.com/sabelos"/>}
//             ];

const steps = [
              {name: 'Step One', component: <OnboardFormComponent/>},
              {name: 'Schedule Call', component: <InlineWidget url="https://calendly.com/thulisile/15min"/>}
            ];

const MultiStep = abc;

class OnboardComponent extends Component {


    constructor(props) {
        super(props);
        this.state = {
            home: true,
            profile: false,

            name: '',
            email: '',
            errors: {},
            my_api_keys: [],
            my_connected_apps: [],

            users: [],
            usersLength: 0,
            message: "",
        };


    }


    render() {
        //var side_bar = document.getElementById('app_side_bar').style.display = 'none';
        // var onboard = <>
        //     <h4>Complete your On-boarding to activate your account</h4>
        //     <MultiStep showNavigation={true} steps={steps}/>
        // </>

        var onboard = <div>
            <h4>Schedule an On-boarding call in order to activate your account</h4>
            <InlineWidget url="https://calendly.com/thulisile/15min"/>
        </div>

        return onboard

    }
}


export default OnboardComponent;
