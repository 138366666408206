import React, {Component} from 'react';
import './api.css';
import axios from "axios";
import {server_base_url} from "../../context/UserContext"; // Import

const constants = {
    "url": server_base_url + "/api/credential/?format=json"
}

class AdminUsersComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            home: true,
            profile: false,

            name: '',
            email: '',
            errors: {},
            my_api_keys: [],
            my_connected_apps: [],

            users: [],
            usersLength: 0,
            message: "",
        };

        //this.delete_key = this.delete_key.bind(this);
        // onClick={(e) => this.delete_key(item._id)}


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitF = this.handleSubmitF.bind(this);
        this.get_keys = this.get_keys(this);


    }


    handleChange(event) {
        if (event.target.name === 'name') {
            this.setState({name: event.target.value});
        } else if (event.target.name === 'email') {
            this.setState({email: event.target.value});
        } else if (event.target.name === 'message') {
            this.setState({message: event.target.value});
        }
    }

    randomString(length, chars) {
        var mask = '';
        if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
        if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        if (chars.indexOf('#') > -1) mask += '0123456789';
        if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
        var result = '';
        for (var i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
        return result;
    }

    handleSubmitF(event) {

        event.preventDefault();
        const {name, email, message} = this.state;
        const conf = {
            'name': name,
            'email': localStorage.getItem('id_token'),
            'api_key': this.randomString(16, '#aA'),
            'api_secret': this.randomString(6, 'aA')
        };
        axios.post(constants["url"], conf)
            .then(response => {
                this.state.users.unshift(response.data);
                this.setState({
                    users: this.state.users,
                    usersLength: this.state.users.length,
                });
                window.location.reload();
            })
            .catch((error) => {
                console.error(error);
            });
    }


    componentDidMount() {
        axios.get(server_base_url + '/api/get_api_keys?email=' + localStorage.getItem('id_token'))
            .then(response => {
                this.setState({
                    users: response.data.reverse(),
                    usersLength: response.data.length,
                });
            })
            .catch((error) => {
                console.log(error);
            });

        this.get_connections();
    }


    onLogoutClick = e => {
        e.preventDefault();
        localStorage.removeItem('jwtToken');

        this.props.logoutUser();
        //location.reload();

    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }

        //this.get_keys();
        // this.get_connections();
    }


    renderTableRows(array) {

        if (array.length > 0) {
            return array.map(item =>
                <tr>
                    <td>{item.first_name} {item.last_name}</td>
                    <td>{item.email}</td>
                    <td>{item.verified_status}</td>
                    <td>
                        {/*{if item.verified_status === 'True':()}*/}
                        <button className="btn btn-warning" id={item.id}
                                onClick={(e) => this.delete_key(item.id, item.verified_status )}> Activate  / De-Activate
                        </button>
                    </td>
                </tr>
            );
        } else {
            return <tr>
                <td colSpan="3"><b>No users were found!</b></td>
            </tr>;

        }

    }

    uniq(a) {
        var seen = {};
        return a.filter(function (item) {
            return seen.hasOwnProperty(item) ? false : (seen[item] = true);
        });
    }

    get_keys() {
        fetch(server_base_url + '/api/get_app_users', {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                this.setState({my_api_keys: response})
            })
            .catch(err => {
                console.log(err);
            });
    }


    delete_key(delete_key_x, verified_status_value) {
        console.log(delete_key_x)

        if (window.confirm('Are you sure you want to Change status of user this?')) {
            // Save it!
            this.confirmDelete(delete_key_x, verified_status_value)
        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }
    }

    confirmDelete = (delete_key_x, verified_status_value) => {


        var bodyFormData = new FormData();
        bodyFormData.append('user_id', delete_key_x);
        bodyFormData.append('verified_status', verified_status_value);

        axios({
            method: "post",
            url: server_base_url + "/api/activate_de_activate_user",
            data: bodyFormData,
            headers: {"Content-Type": "multipart/form-data"},
        })
            .then(function (response) {
                //handle success
                alert('User Status has been changed');
                window.location.reload();


                var obj = response.data;

                console.log(obj);


            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });


    }


    get_connections() {
        fetch(server_base_url + "/api/get_connected_keys?business_name=" + localStorage.getItem('id_token'), {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.error) {
                    //alert('error message');
                } else {
                    this.setState({my_connected_apps: response})
                }

            })
            .catch(err => {
                console.log(err);
            });
    }

    onChangeRegister = e => {
        this.setState({[e.target.id]: e.target.value});
        //this.setState({email:localStorage.getItem("user_email")})
    };



    render() {
        // const { user } = this.props.auth;


        //const {home, profile} = this.state;

        //const {errors, name, password, password2, email, my_api_keys, my_connected_apps} = this.state;

        var alpha =


            <div className="container">
                <h6 className="section-title h1">Activate / De-Activate Users</h6>
                <div className="row">
                    <div className="col-xs-12 " style={{width: '100%'}}>
                        <table className="table">
                            <thead>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Is  Activated?</th>
                            <th>Activate</th>
                            </thead>
                            <tbody>
                            {this.renderTableRows(this.state.my_api_keys)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        return alpha

    }
}

export default AdminUsersComponent;
