import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import websiteMainpage from "../pages/websiteMainpage";
import AboutUsComponent from "../pages/AboutUsComponent";
import WhyUsComponent from "../pages/WhyUsComponent";
import ProductComponent from "../pages/ProductComponent";
import ContactUsComponent from "../pages/ContactUsComponent";

// context
import {useUserState, useUserDispatch} from "../context/UserContext";
import ScrollToTop from "./ScrollToTop";
import NewPasswordComponent from "../pages/NewPasswordComponent";
import PasswordResetComponent from "../pages/PasswordResetComponent";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();

     // var userDispatch = useUserDispatch();

  return (
    <HashRouter>
      <ScrollToTop />
      <Switch>
        {/*<Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />*/}
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        {/*        <Route*/}
        {/*  exact*/}
        {/*  path="/about"*/}
        {/*  render={() => <Redirect to="/about" />}*/}
        {/*/>*/}
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/about" component={AboutUsComponent}/>
        <PublicRoute path="/why-us" component={WhyUsComponent}/>
        <PublicRoute path="/product" component={ProductComponent}/>
        <PublicRoute path="/contact" component={ContactUsComponent}/>
         <PublicRoute path="/new_password" component={NewPasswordComponent}/>
          <PublicRoute path="/reset" component={PasswordResetComponent}/>
          <PublicRoute path="/" component={websiteMainpage} />
        {/*<PublicRoute path="/sign-out" onClick={() => signOut(userDispatch, props.history)}/>*/}
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/app/dashboard",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
