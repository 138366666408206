import React, {Component} from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { logoutUser, registerAPI , deleteKey, getConnections} from '../../redux/actions/authActions';
import './api.css';
import axios from "axios";
// import classnames from 'classnames';
// import { registerAPI } from '../../redux/actions/authActions';
// import  BulletList  from 'react-content-loader';
// import { Link, withRouter } from 'react-router-dom';
// const MyBulletListLoader = () => <BulletList />
// import setAuthToken from '../../utils/setAuthToken';
// import { confirmAlert } from 'react-confirm-alert';
import {mikyaLoginUser} from "../../context/UserContext";
import {Grid} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Widget from "../../components/Widget";
import Table from "./components/Table/Table";
import mock from "./mock";
import {makeStyles} from "@material-ui/styles"; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// const constants = {
//   "url": server_base_url+"/api/credential/?format=json"
// }
import {server_base_url} from "../../context/UserContext";
// import mock from "../dashboard/mock";
// import UserService from "../../services/user.service";
// import EventBus from "../../common/EventBus";

const constants = {
    "url": server_base_url + "/api/credential/?format=json"
}
const useStyles = makeStyles(theme => ({
    tableOverflow: {
        overflow: 'auto'
    }
}))

class AccountComponent extends Component {


    constructor(props) {
        super(props);
        this.state = {
            home: true,
            profile: false,
            content: {},

            name: '',
            email: '',
            errors: {},
            my_api_keys: [],
            my_connected_apps: [],

            users: [],
            usersLength: 0,
            message: "",
        };

        //this.delete_key = this.delete_key.bind(this);
        // onClick={(e) => this.delete_key(item._id)}


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitF = this.handleSubmitF.bind(this);
        this.get_keys = this.get_keys(this);


    }


    handleChange(event) {
        if (event.target.name === 'name') {
            this.setState({name: event.target.value});
        } else if (event.target.name === 'email') {
            this.setState({email: event.target.value});
        } else if (event.target.name === 'message') {
            this.setState({message: event.target.value});
        }
    }

    randomString(length, chars) {
        var mask = '';
        if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
        if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        if (chars.indexOf('#') > -1) mask += '0123456789';
        if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
        var result = '';
        for (var i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
        return result;
    }

    handleSubmitF(event) {
        event.preventDefault();
        const {name, email, message} = this.state;
        const conf = {
            'name': name,
            'email': localStorage.getItem('id_token'),
            'api_key': this.randomString(16, '#aA'),
            'api_secret': this.randomString(6, 'aA')
        };
        axios.post(constants["url"], conf)
            .then(response => {
                this.state.users.unshift(response.data);
                this.setState({
                    users: this.state.users,
                    usersLength: this.state.users.length,
                });
                window.location.reload();
            })
            .catch((error) => {
                console.error(error);
            });
    }


/*    componentDidMount() {
    UserService.getUserBoard().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
    }*/


    onLogoutClick = e => {
        e.preventDefault();
        localStorage.removeItem('jwtToken');

        this.props.logoutUser();
        //location.reload();

    };

// componentDidMount() {
//   // this.get_keys();
//   // this.get_connections();
// }

// 

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }

        this.get_keys();
        // this.get_connections();
    }


    renderTableRows(array) {

        if (array.length > 0) {
            return array.map(item =>
                <tr>
                    <td>{item.name}</td>
                    <td>{item.api_key}</td>
                    <td>
                        <button className="btn btn-danger" id={item.id}
                                onClick={(e) => this.delete_key(item.id)}>Delete
                        </button>
                    </td>
                </tr>
            );
        } else {
            return <tr>
                <td colSpan="3"><b>No API keys were found!</b></td>
            </tr>;

        }

    }

    uniq(a) {
        var seen = {};
        return a.filter(function (item) {
            return seen.hasOwnProperty(item) ? false : (seen[item] = true);
        });
    }

    renderConnectedTableRows(array) {

        //var unique = new Map();
        //array.forEach(d => unique.set(d, d));
        //var uniqueItems = [...unique.keys()];

        if (array.length > 0) {
            return array.map(item =>
                <tr>
                    <td>{item.client_id}</td>
                    <td><span class="dot"></span></td>
                </tr>
            );
        } else {
            return <tr>
                <td colSpan="3"><b>No Connected apps were found!</b></td>
            </tr>;

        }
    }

    get_keys() {
        fetch(server_base_url + '/api/get_api_keys?email=' + localStorage.getItem('id_token'), {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                this.setState({my_api_keys: response})
            })
            .catch(err => {
                console.log(err);
            });
    }


    delete_key(delete_key_x) {
        console.log(delete_key_x)

        if (window.confirm('Are you sure you want to delete this?')) {
            // Save it!
            this.confirmDelete(delete_key_x)
        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }

    }

    confirmDelete = delete_key_x => {

        var bodyFormData = new FormData();
        bodyFormData.append('api_id', delete_key_x);

        axios({
            method: "post",
            url: server_base_url + "/api/delete_api_key",
            data: bodyFormData,
            headers: {"Content-Type": "multipart/form-data"},
        })
            .then(function (response) {
                //handle success
                alert('API Key has been deleted');
                window.location.reload();


                var obj = response.data;


            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });


    }


    get_connections() {
        fetch(server_base_url + "/api/get_connected_keys?business_name=" + localStorage.getItem('id_token'), {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.error) {
                    //alert('error message');
                } else {
                    this.setState({my_connected_apps: response})
                }

            })
            .catch(err => {
                console.log(err);
            });
    }

    onChangeRegister = e => {
        this.setState({[e.target.id]: e.target.value});
        //this.setState({email:localStorage.getItem("user_email")})
    };
    registerSubmit = e => {
        e.preventDefault();
        const newKey = {
            name: this.state.name,
            email: this.state.email
        };
        this.props.registerAPI(newKey, this.props.history);
    };

    switchView(value) {
        if (value == 'home') {
            this.setState({
                home: true,
                profile: false
            })
        } else {
            this.setState({
                home: false,
                profile: true
            })
        }
    }


    render() {
        // const { user } = this.props.auth;
//const classes = useStyles();

        const {home, profile} = this.state;

        const {errors, name, password, password2, email, my_api_keys, my_connected_apps} = this.state;


        var api_dashboard = <section id="tabs">
            <div className="container">
                <h6 className="section-title h1">Account</h6>
                <div className="row">
                    <div className="col-xs-12 " style={{width: '100%'}}>
                        <nav>
                            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                <a className={this.state.home ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                   id="nav-home-tab" data-toggle="tab"
                                   onClick={() => this.switchView('home')} role="tab"
                                   aria-controls="nav-home"
                                   aria-selected={this.state.home ? 'true' : 'false'}>Profile</a>
{/*                                <a className={this.state.profile ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                   id="nav-profile-tab" data-toggle="tab"
                                   onClick={() => this.switchView('profile')} role="tab" aria-controls="nav-profile"
                                   aria-selected={this.state.profile ? 'false' : 'true'}>Employees</a>*/}
                            </div>
                        </nav>
                        <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                            <div className={this.state.home ? 'tab-pane fade show active' : 'tab-pane fade'}
                                 id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">First Name</label>
                                        <input type="text" className="form-control" id="exampleInputEmail1"
                                               aria-describedby="emailHelp" placeholder="First Name"  disabled="true"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Last Name</label>
                                        <input type="text" className="form-control" id="exampleInputEmail1"
                                               aria-describedby="emailHelp" placeholder="Last Name"  disabled="true"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Email address</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1"
                                               aria-describedby="emailHelp" placeholder="Enter email"  disabled="true"/>
                                        <small id="emailHelp" className="form-text text-muted">We'll never share your
                                            email with anyone else.</small>
                                    </div>

                                </form>
                            </div>

{/*                            <div className={this.state.profile ? 'ab-pane fade show active' : 'ab-pane fade'}
                                 id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <MUIDataTable
                                            title="Employee List"
                                            data={datatableData}
                                            columns={["Name", "Company", "City", "State"]}
                                            options={{
                                                filterType: "checkbox",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>*/}

                        </div>

                    </div>
                </div>
            </div>
        </section>

        var dashboard_data = <section id="tabs" className="dashboard">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="content">
                            <div className="row">
                                <div className="col-xs-12 ">
                                    <nav>
                                        <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                            <a className={this.state.home ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                               id="nav-home-tab" data-toggle="tab"
                                               onClick={() => this.switchView('home')} role="tab"
                                               aria-controls="nav-home"
                                               aria-selected={this.state.home ? 'true' : 'false'}>API Keys</a>
                                            <a className={this.state.profile ? 'nav-item nav-link active' : 'nav-item nav-link'}
                                               id="nav-profile-tab" data-toggle="tab"
                                               onClick={() => this.switchView('profile')} role="tab"
                                               aria-controls="nav-profile"
                                               aria-selected={this.state.profile ? 'false' : 'true'}>Connected Apps /
                                                Websites</a>
                                            {/* <a className={this.state.profile ? 'nav-item nav-link active' : 'nav-item nav-link'} id="nav-profile-tab" data-toggle="tab" onClick={() => this.switchView('profile')}  role="tab" aria-controls="nav-profile" aria-selected={this.state.profile ? 'false' : 'true'}>Profile</a> */}
                                        </div>
                                    </nav>
                                    <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                        <div className={this.state.home ? 'tab-pane fade show active' : 'tab-pane fade'}
                                             id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <form noValidate onSubmit={this.registerSubmit}>
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label htmlFor="name">App Name</label> <br/>
                                                        <input
                                                            type="text"
                                                            className="input-control"
                                                            placeholder="Name of APP"
                                                            id="name"
                                                            value={name}
                                                            onChange={this.onChangeRegister}
                                                            error={errors.name}


                                                        />{' '}
                                                        <button type="submit" className="btn btn-md btn-register">
                                                            Create API Key
                                                        </button>
                                                        <br/>
                                                        <span className="text-danger">{errors.name}</span>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        {/* <label htmlFor="Email">Email</label> <br /> */}
                                                        <input
                                                            type="hidden"
                                                            className="input-control"
                                                            placeholder="Enter your email"
                                                            id="email"
                                                            value={localStorage.getItem("user_email")}
                                                            onChange={this.onChangeRegister}
                                                            error={errors.email}

                                                        />{' '}
                                                    </div>
                                                </div>
                                            </form>
                                            <table className="table">
                                                <thead>
                                                <th>APP Name</th>
                                                <th>API Secret</th>
                                                <th>Delete</th>
                                                </thead>
                                                <tbody>
                                                {this.renderTableRows(this.state.my_api_keys)}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div
                                            className={this.state.profile ? 'ab-pane fade show active' : 'ab-pane fade'}
                                            id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            <table className="table">
                                                <thead>
                                                <th>API</th>
                                                <th>Connection Status</th>
                                                </thead>
                                                <tbody>
                                                {this.renderConnectedTableRows(this.state.my_connected_apps)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        return api_dashboard

        // return (
        //   <section className="dashboard">
        //     <div className="container">
        //       <div className="row">
        //         <div className="col-12">
        //           <div className="content">
        //             <h1>
        //               H! <b>{user.name.split(' ')[0]} </b>
        //             </h1>
        //             <h3>
        //               You are Successfully logged into a Full-stack MERN Application
        //             </h3>
        //             <button
        //               onClick={this.onLogoutClick}
        //               className="btn btn-lg btn-warning mt-5"
        //             >
        //               Logout
        //             </button>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </section>
        // );
    }
}

// Dashboard.propTypes = {
//   registerAPI: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired
// };
// const mapStateToProps = state => ({
//   auth: state.auth,
//   errors: state.errors
// });
//export default connect(mapStateToProps, { registerAPI, logoutUser, deleteKey, getConnections })(withRouter(APIKeyCreation));

export default AccountComponent;
