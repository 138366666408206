import React from 'react';
import './web_files/css/bootstrap-slider.min.css';
import './web_files/css/slick.css';
import './web_files/css/style.css';
import './web_files/css/custom.css';
import {withRouter} from 'react-router-dom';
import {Nav, Navbar, Container} from 'react-bootstrap';
import logo_2 from "./web_files/images/logo_2.png";
import ninety_five_percent from "./web_files/images/95.png";
import why_us from "./web_files/images/why_us.png";
import ninety_percent from "./web_files/images/90.png";
import hundred_percent from "./web_files/images/100.png";
import blob_two from "./web_files/images/blob_two.png";

function ProductComponent() {
    return (
        <div>
            <meta charSet="UTF-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0,maximum-scale=1.0, user-scalable=no"/>
            <title>CreditAIS - Products</title>
            <div className="inner-header about-header">
                <Navbar expand='sm' variant="dark" sticky="top" className="custom_header">
                    <Container>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Brand href="#home"><img src={logo_2} alt="logo"/></Navbar.Brand>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link href="#/">Home</Nav.Link>
                                <Nav.Link href="#/about">About Us</Nav.Link>
                                <Nav.Link href="#/products" className="active">Products</Nav.Link>
                                <Nav.Link href="#/why-us">Why use it?</Nav.Link>
                                <Nav.Link href="#/contact">Contact Us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <div id="page-head" className="container-fluid inner-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company-info-holder">
                                <div className="row">
                                    <div className="col-12 col-md-4 mb-3">
                                        <h4 style={{color: '#0B0134', fontSize: '26px'}}>Our Products & Solutions</h4>
                                    </div>
                                    <div className="col-12 col-md-8">
                                        <div style={{color: '#0B0134', fontSize: '18px', lineHeight: '1.6'}}>
                                            Transforming satellite data and AI into actionable intelligence for business, government, and environmental organizations.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Solutions Section */}
            <div id="main-solutions" className="container-fluid" style={{padding: '60px 0', background: '#f8f9fa'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 style={{color: '#0B0134', fontSize: '30px', marginBottom: '40px', textAlign: 'center'}}>Main Solutions</h2>
                            
                            <div className="row">
                                <div className="col-md-6">
                                    <div style={{
                                        backgroundColor: '#fff', 
                                        padding: '30px', 
                                        borderRadius: '10px',
                                        boxShadow: '0 5px 15px rgba(0,0,0,0.08)',
                                        height: '100%'
                                    }}>
                                        <h3 style={{color: '#120F7F', marginBottom: '20px'}}>Earth Observation Solutions</h3>
                                        <p style={{fontSize: '18px'}}>
                                            We provide innovative services that translate satellite imagery into business intelligence for agriculture, urban planning, disaster response, and environmental monitoring. All our data are SANSA-verified for accuracy.
                                        </p>
                                    </div>
                                </div>
                                
                                <div className="col-md-6">
                                    <div style={{
                                        backgroundColor: '#fff', 
                                        padding: '30px', 
                                        borderRadius: '10px',
                                        boxShadow: '0 5px 15px rgba(0,0,0,0.08)',
                                        height: '100%'
                                    }}>
                                        <h3 style={{color: '#120F7F', marginBottom: '20px'}}>AI-Powered Business Tools</h3>
                                        <p style={{fontSize: '18px'}}>
                                            Increase effectiveness with AI-based solutions for customer self-service, procurement, contracts, and workforce management—created specifically for African businesses and government entities.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Product Categories */}
            <div id="product-categories" className="container-fluid" style={{padding: '60px 0'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 style={{color: '#0B0134', fontSize: '30px', marginBottom: '40px', textAlign: 'center'}}>Product Categories</h2>
                            
                            <div className="row">
                                <div className="col-md-6" style={{marginBottom: '30px'}}>
                                    <div style={{
                                        backgroundColor: '#120F7F', 
                                        color: 'white',
                                        padding: '30px', 
                                        borderRadius: '10px',
                                        height: '100%'
                                    }}>
                                        <h3 style={{marginBottom: '20px', fontWeight: 'bold'}}>Smart Agricultural Monitoring</h3>
                                        <ul style={{
                                            listStyleType: 'disc',
                                            paddingLeft: '20px',
                                            fontSize: '18px'
                                        }}>
                                            <li>Automated land verification</li>
                                            <li>Crop health assessment</li>
                                            <li>Weather & disaster risk scoring</li>
                                            <li>Yield predictions</li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="col-md-6" style={{marginBottom: '30px'}}>
                                    <div style={{
                                        backgroundColor: '#120F7F', 
                                        color: 'white',
                                        padding: '30px', 
                                        borderRadius: '10px',
                                        height: '100%'
                                    }}>
                                        <h3 style={{marginBottom: '20px', fontWeight: 'bold'}}>Infrastructure & Urban Planning</h3>
                                        <ul style={{
                                            listStyleType: 'disc',
                                            paddingLeft: '20px',
                                            fontSize: '18px'
                                        }}>
                                            <li>Building footprint tracking</li>
                                            <li>Infrastructure condition monitoring</li>
                                            <li>Urban growth analysis</li>
                                            <li>Disaster risk mapping</li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="col-md-6" style={{marginBottom: '30px'}}>
                                    <div style={{
                                        backgroundColor: '#120F7F', 
                                        color: 'white',
                                        padding: '30px', 
                                        borderRadius: '10px',
                                        height: '100%'
                                    }}>
                                        <h3 style={{marginBottom: '20px', fontWeight: 'bold'}}>Environmental & Resource Management</h3>
                                        <ul style={{
                                            listStyleType: 'disc',
                                            paddingLeft: '20px',
                                            fontSize: '18px'
                                        }}>
                                            <li>Water resource monitoring</li>
                                            <li>Wildfire tracking</li>
                                            <li>Carbon credit validation</li>
                                            <li>ESG compliance verification</li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="col-md-6" style={{marginBottom: '30px'}}>
                                    <div style={{
                                        backgroundColor: '#120F7F', 
                                        color: 'white',
                                        padding: '30px', 
                                        borderRadius: '10px',
                                        height: '100%'
                                    }}>
                                        <h3 style={{marginBottom: '20px', fontWeight: 'bold'}}>AI Business Solutions</h3>
                                        <ul style={{
                                            listStyleType: 'disc',
                                            paddingLeft: '20px',
                                            fontSize: '18px'
                                        }}>
                                            <li>Multilingual virtual assistants</li>
                                            <li>Procurement optimization</li>
                                            <li>Legal document analysis</li>
                                            <li>HR analytics & optimization</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Tailor Your Needs */}
            <div id="tailor-needs" className="container-fluid" style={{padding: '60px 0', background: '#f8f9fa'}}>
                <div className="container">
                    <h2 style={{color: '#0B0134', fontSize: '28px', marginBottom: '40px', textAlign: 'center'}}>Tailor Your Needs</h2>
                    <div className="row">
                        {/* For Government Agencies */}
                        <div className="col-12 col-md-4 mb-4">
                            <div className="solution-card" style={{
                                backgroundColor: '#120F7F',
                                color: 'white',
                                padding: '25px',
                                borderRadius: '10px',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <h3 style={{fontSize: '24px', marginBottom: '20px'}}>For Government Agencies</h3>
                                <ul style={{
                                    listStyleType: 'none',
                                    padding: '0',
                                    margin: '0',
                                    flex: '1'
                                }}>
                                    <li className="mb-3">• Monitor infrastructure development</li>
                                    <li className="mb-3">• Track urban expansion</li>
                                    <li className="mb-3">• Manage disaster response</li>
                                    <li className="mb-3">• Automate public services</li>
                                </ul>
                                <a href="#/contact" className="ybtn ybtn-white-outline mt-3">Learn More</a>
                            </div>
                        </div>

                        {/* For Business */}
                        <div className="col-12 col-md-4 mb-4">
                            <div className="solution-card" style={{
                                backgroundColor: '#120F7F',
                                color: 'white',
                                padding: '25px',
                                borderRadius: '10px',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <h3 style={{fontSize: '24px', marginBottom: '20px'}}>For Business</h3>
                                <ul style={{
                                    listStyleType: 'none',
                                    padding: '0',
                                    margin: '0',
                                    flex: '1'
                                }}>
                                    <li className="mb-3">• Optimize operations with AI</li>
                                    <li className="mb-3">• Ensure regulatory compliance</li>
                                    <li className="mb-3">• Streamline procurement</li>
                                    <li className="mb-3">• Enhance customer service</li>
                                </ul>
                                <a href="#/contact" className="ybtn ybtn-white-outline mt-3">Learn More</a>
                            </div>
                        </div>

                        {/* For Environmental Organizations */}
                        <div className="col-12 col-md-4 mb-4">
                            <div className="solution-card" style={{
                                backgroundColor: '#120F7F',
                                color: 'white',
                                padding: '25px',
                                borderRadius: '10px',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <h3 style={{fontSize: '24px', marginBottom: '20px'}}>For Environmental Orgs</h3>
                                <ul style={{
                                    listStyleType: 'none',
                                    padding: '0',
                                    margin: '0',
                                    flex: '1'
                                }}>
                                    <li className="mb-3">• Monitor conservation efforts</li>
                                    <li className="mb-3">• Validate carbon credits</li>
                                    <li className="mb-3">• Track environmental changes</li>
                                    <li className="mb-3">• Ensure ESG compliance</li>
                                </ul>
                                <a href="#/contact" className="ybtn ybtn-white-outline mt-3">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Call to Action */}
            <div id="cta" className="container-fluid" style={{padding: '60px 0', background: 'white'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 style={{color: '#0B0134', fontSize: '30px', marginBottom: '20px'}}>Take the Next Step</h2>
                            <p style={{fontSize: '20px', marginBottom: '30px'}}>
                                Start using Creditais today to harness the power of Earth Observation data and AI for better business decisions.
                            </p>
                            <div>
                                <a href="/#/login" className="ybtn ybtn-accent-color ybtn-shadow" style={{marginRight: '15px'}}>Get Started</a>
                                <a href="/#/contact" className="ybtn ybtn-white ybtn-shadow">Request Demo</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Footer */}
            <div id="footer" className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="address-holder">
                                <div>
                                    <img src={logo_2} alt="CreditAIS Logo"/>
                                    <br/>
                                    <br/>
                                </div>
                                <p>
                                    Explore how you can use satellite data and artificial intelligence to make better decisions in agriculture, infrastructure, environmental monitoring, and business.
                                    <br/><br/>
                                </p>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="address-holder">
                                <div style={{color: 'white', fontSize: '20px'}}>Address</div>
                                <br/>
                                <div>
                                    <p>11th Floor, The Bank - Rosebank<br/>Cradock Ave,<br/>Johannesburg,<br/>South
                                        Africa</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="footer-menu-holder">
                                <h4>Navigate</h4>
                                <ul className="footer-menu">
                                    <li><a href="#/">Home</a></li>
                                    <li><a href="#/login">Login / Register </a></li>
                                    <li><a href="#/products">Products</a></li>
                                    <li><a href="#/why-us">Why Us</a></li>
                                    <li><a href="#/about">About Us</a></li>
                                    <li><a href="#/contact">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="footer-menu-holder">
                                <h4>Social</h4>
                                <ul className="footer-menu">
                                    <li><a href="https://www.facebook.com/CreditAis-1109869665878153/"
                                           target="_blank">Facebook</a></li>
                                    <li><a href="https://twitter.com/creditaisloan" target="_blank">Twitter</a></li>
                                    <li><a href="https://www.linkedin.com/company/creditais/about/"
                                           target="_blank">Linkedin</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(ProductComponent);