import React, {Component} from 'react';
import './web_files/css/bootstrap-slider.min.css';
import './web_files/css/slick.css';
import './web_files/css/style.css';
import './web_files/css/custom.css';
import {Nav, Navbar, Container} from 'react-bootstrap';
import mockup from "./web_files/images/mockup.png";
import sponsor_dazzle from "./web_files/images/sponsor_dazzle.png";
import google_support from "./web_files/images/google.jpg";
import stencil_logo from "./web_files/images/stencil_logo.png";
import logo_2 from "./web_files/images/logo_2.png"
import satelitte from "./web_files/images/satelitte.png"
import ai from "./web_files/images/ai.png"
import {toast, ToastContainer} from "react-toastify";

class websiteMainpage extends Component {


    constructor(props) {
        super(props);
        this.state = {
            home: true,
            profile: false,
            name: '',
            email: '',
            errors: {},
            my_api_keys: [],
            my_connected_apps: [],
            users: [],
            usersLength: 0,
            message: "",
        };

    }

    notifyError = (msg) => toast.error(msg);

    userRedirect(selector_elem)
    {
       var user_value =document.getElementById(''+selector_elem+'').value;
       // alert(user_value);

        if(user_value)
        {
            if(this.ValidateEmail(user_value))
            {
              window.location.href="#/login?email="+user_value;
            }else{
                this.notifyError('Invalid Email Address')
            }


        }else{
          this.notifyError('Enter your email address')
        }

    }


    ValidateEmail(input) {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        return !!input.match(validRegex);
    }



    render() {
        var api_dashboard =
            <div>
                <div id="header-holder" className="main-header">
                    <div className="bg-animation">
                        <div className="graphic-show">
                            <img className="fix-size" src="images/graphic1.png" alt=""/>
                            <img className="img img1" src="images/graphic1.png" alt=""/>
                            <img className="img img2" src="images/graphic2.png" alt=""/>
                            <img className="img img3" src="images/graphic3.png" alt=""/>
                        </div>
                    </div>

                    <Navbar expand='sm' variant="dark">
                        <Container>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                            <Navbar.Brand href="#home"><img src={logo_2} alt="logo"
                            /></Navbar.Brand>
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav >
                                    <Nav.Link href="#/">Home</Nav.Link>
                                    <Nav.Link href="#/about">About Us</Nav.Link>
                                    <Nav.Link href="#/why-us">Why use it? </Nav.Link>
                                    <Nav.Link href="#/product">Our Product Offering </Nav.Link>

                                    {/*<Nav.Link href="#/contact">Contact Us </Nav.Link>*/}
                                    {/*<Nav.Link href="#/login"  style={{color:'white', borderStyle: 'solid', borderWidth: 'thin'}}>Login / Sign up </Nav.Link>*/}
                                    {/*<a href="/#/login" className="ybtn ybtn-header-color" style={{color:'#120F7F', backgroundColor:'white'}}>Sign UP</a>*/}
                                </Nav>
                                <Nav className="ml-auto">
                                    <Nav.Link href="#/contact" className="nav-contact-btn" style={{color: 'white'}}>Get in Touch</Nav.Link>
                                    <Nav.Link href="#/login" className="nav-login-btn" style={{color:'white', borderStyle: 'solid', borderWidth: 'thin', paddingLeft: '20px', paddingRight: '20px', marginLeft: '15px', backgroundColor: '#120F7F', borderRadius: '5px'}}>Login / Sign up</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>

                    <div id="top-content" className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div id="main-slider">
                                        <div className="slide">
                                            <div className="noti-holder">
                                            </div>
                                            <div className="spacer"/>
                                            <div className="big-title">Transform Your Business with AI-Powered Satellite Intelligence</div>
                                            <p>We are a technology company that helps organizations achieve operational excellence through the integration of artificial intelligence and satellite technology in agriculture, infrastructure, and other sectors.</p>
                                            <div className="btn-holder">
                                                <input className="user_email" placeholder="Enter your email" id="user_email"/>
                                                <a className="ybtn ybtn-header-color" onClick={()=> this.userRedirect('user_email')} style={{color:'#120F7F', backgroundColor:'white', cursor: 'pointer', fontWeight: 'bold'}}>Sign up for AI-driven insights</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" id="mockup-image">
                                    <img src={mockup} alt=""
                                         style={{width: '500px', height: '400px', marginBottom: '-600px'}}/>
                                    {/*<img className="header-graphic" src="images/graphic1.png" alt="" />*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="info" className="container-fluid">

                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div style={{color: '#0B0134', fontSize: '50PX'}}>Our Partnerships </div>
                                <br/>
                                
                                    <div>
                                    <img src={google_support} alt=""
                  style={{ width: '294px', height: '166px', marginRight: '50px' }} />
                {/* <img src={stencil_logo} alt=""
                  style={{ width: '220px', height: '83px', marginRight: '50px' }} /> */}
              
                                </div>
                                <br />
                                {/* <p style={{ color: '#0B0134', fontSize: '20PX' }}>Lenders leverage historical client data, advances in machine learning algorithms, and alternative datasets to build a robust credit risk model. This results in more applicants being considered for available funding, an increase in the lending universe for a lender without an increase in non-performing loans and a delightful experience for borrowers.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="services" className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row-title">Our Solutions</div>
                            </div>
                        </div>
                        <div id="main-solutions" className="container-fluid" style={{padding: '60px 0', background: '#f8f9fa'}}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div style={{
                                                backgroundColor: '#fff', 
                                                padding: '30px', 
                                                borderRadius: '10px',
                                                boxShadow: '0 5px 15px rgba(0,0,0,0.08)',
                                                height: '100%',
                                                marginBottom: '20px'
                                            }}>
                                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                                                    <img src={satelitte} alt="Satellite" style={{width: '40px', height: '40px', marginRight: '15px'}}/>
                                                    <h3 style={{color: '#120F7F', margin: '0'}}>Earth Observation Solutions</h3>
                                                </div>
                                                <p style={{fontSize: '16px', lineHeight: '1.6'}}>
                                                    Transform satellite imagery into actionable business intelligence for:
                                                    <ul style={{marginTop: '10px', paddingLeft: '20px'}}>
                                                        <li>Agricultural monitoring and yield prediction</li>
                                                        <li>Urban planning and infrastructure assessment</li>
                                                        <li>Environmental monitoring and compliance</li>
                                                        <li>Disaster response and risk assessment</li>
                                                    </ul>
                                                </p>
                                                <a href="#/product" className="ybtn ybtn-accent-color" style={{marginTop: '15px'}}>Learn More</a>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div style={{
                                                backgroundColor: '#fff', 
                                                padding: '30px', 
                                                borderRadius: '10px',
                                                boxShadow: '0 5px 15px rgba(0,0,0,0.08)',
                                                height: '100%',
                                                marginBottom: '20px'
                                            }}>
                                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
                                                    <img src={ai} alt="AI" style={{width: '40px', height: '40px', marginRight: '15px'}}/>
                                                    <h3 style={{color: '#120F7F', margin: '0'}}>AI-Powered Business Tools</h3>
                                                </div>
                                                <p style={{fontSize: '16px', lineHeight: '1.6'}}>
                                                    Enhance your operations with AI solutions designed for African businesses:
                                                    <ul style={{marginTop: '10px', paddingLeft: '20px'}}>
                                                        <li>Automated customer service systems</li>
                                                        <li>Smart procurement and inventory management</li>
                                                        <li>Intelligent contract analysis</li>
                                                        <li>AI-driven workforce optimization</li>
                                                    </ul>
                                                </p>
                                                <a href="#/product" className="ybtn ybtn-accent-color" style={{marginTop: '15px'}}>Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="message1" className="container-fluid message-area">
                    <div className="bg-color"/>
                </div>

                <div id="testimonials" className="container-fluid">
                    <div className="bg-color"/>
                    <div className="container">
                    </div>
                </div>
                <div id="more-features" className="container-fluid">
                    <div className="container" style={{transform: "skewY(8deg)"}}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row-title" style={{marginBottom: '40px'}}>Why Choose Us?</div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div style={{
                                            backgroundColor: '#fff',
                                            padding: '25px',
                                            borderRadius: '10px',
                                            boxShadow: '0 3px 10px rgba(0,0,0,0.05)',
                                            height: '100%',
                                            textAlign: 'center'
                                        }}>
                                            <h4 style={{color: '#120F7F', marginBottom: '15px'}}>SANSA-Verified Data</h4>
                                            <p>All our satellite data is verified by the South African National Space Agency for accuracy and reliability.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div style={{
                                            backgroundColor: '#fff',
                                            padding: '25px',
                                            borderRadius: '10px',
                                            boxShadow: '0 3px 10px rgba(0,0,0,0.05)',
                                            height: '100%',
                                            textAlign: 'center'
                                        }}>
                                            <h4 style={{color: '#120F7F', marginBottom: '15px'}}>African-Focused Solutions</h4>
                                            <p>Our AI tools are specifically designed for African businesses and conditions, ensuring relevant and practical solutions.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div style={{
                                            backgroundColor: '#fff',
                                            padding: '25px',
                                            borderRadius: '10px',
                                            boxShadow: '0 3px 10px rgba(0,0,0,0.05)',
                                            height: '100%',
                                            textAlign: 'center'
                                        }}>
                                            <h4 style={{color: '#120F7F', marginBottom: '15px'}}>Expert Support</h4>
                                            <p>Get dedicated support from our team of satellite imagery and AI experts throughout your journey.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                            <div id="footer" className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="address-holder">
                                <div>
                                    <img src={logo_2}/>
                                    <br/>
                                    <br/>
                                </div>
                                <p>
                                    {/*CreditAIs provides access to credit for small businesses through the use of*/}
                                    {/*machine learning applied to alternative data.*/}
                                    Explore how you can use satellite data and artificial intelligence to make better decisions in agriculture, infrastructure, environmental monitoring, and business.
                                    <br/><br/>
                                </p>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="address-holder">
                                <div style={{color: 'white', fontSize: '20px'}}>Address</div>
                                <br/>
                                <div>
                                    <p>11th Floor, The Bank - Rosebank<br/>Cradock Ave,<br/>Johannesburg,<br/>South
                                        Africa</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="footer-menu-holder">
                                <h4>Navigate</h4>
                                <ul className="footer-menu">
                                    <li><a href="#/">Home</a></li>
                                    <li><a href="#/login">Login / Register </a></li>
                                    <li><a href="#/why-us">Why Us</a></li>
                                    <li><a href="#/product">Our Product Offering</a></li>
                                    <li><a href="#/about">About Us</a></li>
                                    <li><a href="#/contact">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="footer-menu-holder">
                                <h4>Social</h4>
                                <ul className="footer-menu">
                                    <li><a href="https://www.facebook.com/CreditAis-1109869665878153/" target="_blank">Facebook</a></li>
                                    {/*<li><a href="#/login" target="_blank">Instagram</a></li>*/}
                                    <li><a href="https://twitter.com/creditaisloan" target="_blank">Twitter</a></li>
                                    <li><a href="https://www.linkedin.com/company/creditais/about/" target="_blank">Linkedin</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <ToastContainer />
            </div>

        return api_dashboard;

    }
}


export default websiteMainpage;
