import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';


function OnboardFormComponent(props) {

    return (
        <div>
            <div id="team" className="container-fluid">
                <h4 style={{color: '#0B0134', fontSize: '26PX'}}>Schedule a call with us</h4>
                <div className="container">
                    <form style={{width: '50%', margin: 'auto'}}>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="exampleInputEmail1">First Name</label>
                                <input type="text" className="form-control" placeholder="First name"/>
                            </div>
                            <div className="col">
                                <label htmlFor="exampleInputEmail1">Last Name</label>
                                <input type="text" className="form-control" placeholder="Last name"/>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="exampleInputEmail1">Mobile Number</label>
                                <input type="text" className="form-control" placeholder="Mobile Number"/>
                            </div>
                            <div className="col">
                                <label htmlFor="exampleInputEmail1">Subject</label>
                                <input type="text" className="form-control" placeholder="Subject"/>
                            </div>
                        </div>
                        <br/>
                        <div className="form-group">
                            {/*<label htmlFor="exampleInputEmail1">Email address</label>*/}
                            <textarea className="form-control" id="exampleFormControlTextarea1"
                                      placeholder="Send Message" rows="3"></textarea>
                            {/*<small id="emailHelp" className="form-text text-muted">We'll never share your email with*/}
                            {/*    anyone else.</small>*/}
                        </div>
                        <button type="submit" className="btn btn-primary" style={{display: 'block'}}>Send Message
                        </button>
                    </form>

                </div>
            </div>

        </div>
    );


}


export default withRouter(OnboardFormComponent);
// export default AboutUsComponent;
