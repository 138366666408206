import React, {Component, useState} from "react";
import {
    Grid,
    LinearProgress,
    Select,
    OutlinedInput,
    MenuItem,
    Button, CircularProgress
} from "@material-ui/core";
import {useTheme} from "@material-ui/styles";
import {
    ResponsiveContainer,
    ComposedChart,
    AreaChart,
    LineChart,
    Line,
    Area,
    PieChart,
    Pie,
    Cell,
    YAxis,
    XAxis, Bar, BarChart,Tooltip, Legend, CartesianGrid
} from "recharts";

import axios from "axios";
import {server_base_url} from "../../context/UserContext";
import {ShimmerTable} from "react-shimmer-effects";
// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import {Typography} from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import APIKeyCreation from "./APIKeyCreation";
// import {BarChart} from "@material-ui/icons";
// import APIKeyCreation from "../../pages/dashboard";

import OnboardComponent from "./OnboardComponent";

const API_URL = server_base_url + "/api/";

class BusinessDashboard extends Component {


    constructor(props) {
        super(props);
        this.state = {
            home: true,
            profile: false,

            name: '',
            email: '',
            errors: {},
            my_api_keys: [],
            my_connected_apps: [],
            content: {},
            users: [],
            api_data:null,
            usersLength: 0,
            message: "",
            datatableData: null,
            onboard: localStorage.getItem('verified_status')
        };


    }


    componentDidMount() {

        var self = this;
        var bodyFormData = new FormData();
        bodyFormData.append('email', "'" + localStorage.getItem('id_token') + "'");
        // bodyFormData.append('applicationID', application_id);
        //bodyFormData.append('fullObj', JSON.stringify(obj));
        axios({
            method: "get",
            url: API_URL + 'get_insights/?email='+ localStorage.getItem('id_token'),
            headers: {"Content-Type": "multipart/form-data"},
        })
            .then(function (response) {
              var sortedResults = [];


                var parsedJSON = response.data;
                for (var i = 0; i < parsedJSON.length; i++) {

                    sortedResults = parsedJSON[i].graph_data

                    // sortedResults.push({
                    //     name: ''+parsedJSON[i].date_banked+'',
                    //     amount: parsedJSON[i].amount,
                    // })
                }

                //api_data = sortedResults;

                self.setState({
                    api_data: sortedResults
                });

                 console.log('DATA MIKYA')
                 console.log(this.state.api_data)

                console.log('XXXX GGG')
                //console.log(mainChartData)



            })
            .catch(function (response) {
            });
    }



    render() {
    var classes = useStyles;




    var theme = useTheme;
         const isLoaded = this.state.api_data;
        let loaded_component;
        if (isLoaded) {
            loaded_component =                 <>
                    <PageTitle title="My Dashboard"/>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>

                            <br/>
                            <br/>



                            <Widget
                                bodyClass={classes.mainChartBody}
                                header={
                                    <div className={classes.mainChartHeader}>
                                        <Typography
                                            variant="h5"
                                            color="text"
                                            colorBrightness="secondary"
                                        >
                                            Data Insights (Derived from Datasets and credit applications)
                                        </Typography>
                                    </div>
                                }
                            >
                                <ResponsiveContainer width="100%" minWidth={500} height={350}>


                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={this.state.api_data}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 10,
                                        }}
                                        barSize={20}
                                    >
                                        <XAxis dataKey="name" scale="point" padding={{left: 10, right: 10}}/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Legend/>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <Bar dataKey="amount" fill="#8884d8" background={{fill: '#eee'}}/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Widget>
                        </Grid>
                    </Grid>
                    <br/>
                    <br/>


                </>;
        } else {
            loaded_component = <ShimmerTable row={5} col={5}/>;
        }

        // var api_dashboard = <>
        //     <PageTitle title="My Applications"/>
        //
        //     <Grid container spacing={4}>
        //
        //         <Grid item xs={12}>
        //
        //             {loaded_component}
        //         </Grid>
        //     </Grid>
        // </>


    // local
    // var [mainChartState, setMainChartState] = useState("monthly");
    // var [onboard, setOnboard] = useState(localStorage.getItem('verified_status'));
    //var [data, setData] = useState([]);

        console.log('this.state.pay_data');
        console.log(this.state.api_data);



    return (
                <>
            {this.state.onboard && this.state.onboard === "False" ? (<OnboardComponent/>) : (
                <>
                    {loaded_component}

                </>
            )};
            <br/>


        </>
    );

    }
}


export default BusinessDashboard;
