import React, {Component} from 'react';
import './web_files/css/bootstrap-slider.min.css';
import './web_files/css/slick.css';
import './web_files/css/style.css';
import './web_files/css/custom.css';
import {Link, withRouter} from 'react-router-dom';
import {Nav, Navbar, Container} from 'react-bootstrap';
import logo_2 from "./web_files/images/logo_2.png"
import useStyles from "./login/styles";
import {InlineWidget} from "react-calendly";
import blob from "./web_files/images/blob.png";
import blob_two from "./web_files/images/blob_two.png";
import mail from "./web_files/images/mail.png";
import map from "./web_files/images/map.png";

function ContactUsComponent(props) {

    var classes = useStyles();


    return (
        <div>
            <meta charSet="UTF-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0,maximum-scale=1.0, user-scalable=no"/>
            <title>CreditAIS</title>
            <div className="inner-header about-header">
                <Navbar expand='sm' variant="dark" sticky="top" className="custom_header">
                    <Container>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Brand href="#home"><img src={logo_2} alt="logo"
                        /></Navbar.Brand>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link href="#/">Home</Nav.Link>
                                <Nav.Link href="#/about">About Us</Nav.Link>
                                <Nav.Link href="#/why-us">Why use it? </Nav.Link>
                                <Nav.Link href="#/product">Our Product Offering </Nav.Link>
                                <Nav.Link href="#/contact">Contact Us </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <div id="page-head" className="container-fluid inner-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 company-info-holder" style={{display: 'flex'}}>

                                {/*<div className="col-sm-12 col-md-12">*/}
                                <span style={{color: '#0B0134', fontSize: '26PX'}}>Feel Free to Connect With Us</span>
                                {/*</div>*/}
                            </div>
                            <br/>
                            <br/>
                            <div className="col-md-12 company-info-holder" style={{marginTop: '50px'}}>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-sm-6 mb-4">
                                        <div className="contact-card" style={{
                                            background: 'white',
                                            borderRadius: '10px',
                                            padding: '25px',
                                            height: '100%',
                                            boxShadow: '0 5px 15px rgba(0,0,0,0.08)'
                                        }}>
                                            <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                                                <img src={mail} alt="Email" style={{
                                                    width: '50px',
                                                    height: '50px'
                                                }}/>
                                                <span style={{paddingLeft: '15px'}}>
                                                    <div style={{color: '#0B0134', fontSize: '18px', fontWeight: '600'}}>
                                                        Drop a line
                                                    </div>
                                                    <div style={{color: '#0B0134', fontSize: '15px'}}>
                                                        Mail Us
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="text-other-color2" style={{fontSize: '16px'}}>
                                                thulisile@creditais.com
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-12 col-sm-6 mb-4">
                                        <div className="contact-card" style={{
                                            background: 'white',
                                            borderRadius: '10px',
                                            padding: '25px',
                                            height: '100%',
                                            boxShadow: '0 5px 15px rgba(0,0,0,0.08)'
                                        }}>
                                            <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                                                <img src={map} alt="Location" style={{
                                                    width: '50px',
                                                    height: '50px'
                                                }}/>
                                                <span style={{paddingLeft: '15px'}}>
                                                    <div style={{color: '#0B0134', fontSize: '18px', fontWeight: '600'}}>
                                                        Headquarters
                                                    </div>
                                                    <div style={{color: '#0B0134', fontSize: '15px'}}>
                                                        Visit Us
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="text-other-color2" style={{fontSize: '16px'}}>
                                                11th Floor, The Bank - Rosebank,
                                                Cradock Ave, Johannesburg, South Africa
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <br/>
                        </div>
                    </div>
                    <img src={blob_two} alt="Creditais Home Page" style={{
                                    width: '300px',
                                    height: '407px',
                                    marginBottom: '-900px',
                                    marginRight: '-60px'
                                }}/>
                </div>
            </div>
{/*            <div id="team" className="container-fluid">
                <h4 style={{color: '#0B0134', fontSize: '26PX'}}>Schedule a call with us</h4>
                                                                                                                    <img src={blob} alt="Creditais Home Page" style={{
                                    width: '300px',
                                    height: '407px',
                                    marginBottom: '-900px',
                                    marginRight: '-60px'
                                }}/>
                <div className="container">
                    <InlineWidget url="https://calendly.com/sabelos"/>
                </div>
                                                                                                                    <img src={blob} alt="Creditais Home Page" style={{
                                    width: '300px',
                                    height: '407px',
                                    marginBottom: '-700px',
                                    marginRight: '60px'
                                }}/>

            </div>*/}

            <div id="footer" className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="address-holder">
                                <div>
                                    <img src={logo_2}/>
                                    <br/>
                                    <br/>
                                </div>
                                <p>
{/*                                    CreditAIs provides access to credit for small businesses through the use of
                                    machine learning applied to alternative data.*/}
                                    Explore how you can use satellite data and artificial intelligence to make better decisions in agriculture, infrastructure, environmental monitoring, and business.
                                    <br/><br/>
                                </p>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="address-holder">
                                <div style={{color: 'white', fontSize: '20px'}}>Address</div>
                                <br/>
                                 <div>
                                    <p>11th Floor, The Bank - Rosebank<br/>Cradock Ave,<br/>Johannesburg,<br/>South
                                        Africa</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="footer-menu-holder">
                                <h4>Navigate</h4>
                                <ul className="footer-menu">
                                    <li><a href="#/">Home</a></li>
                                    <li><a href="#/login">Login / Register </a></li>
                                    <li><a href="#/why-us">Why Us</a></li>
                                    <li><a href="#/product">Our Product Offering</a></li>
                                    <li><a href="#/about">About Us</a></li>
                                    <li><a href="#/contact">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-3 col-md-3">
                            <div className="footer-menu-holder">
                                <h4>Social</h4>
                                <ul className="footer-menu">
                                    <li><a href="https://www.facebook.com/CreditAis-1109869665878153/" target="_blank">Facebook</a></li>
                                    {/*<li><a href="#/login" target="_blank">Instagram</a></li>*/}
                                    <li><a href="https://twitter.com/creditaisloan" target="_blank">Twitter</a></li>
                                    <li><a href="https://www.linkedin.com/company/creditais/about/" target="_blank">Linkedin</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


}


export default withRouter(ContactUsComponent);
// export default AboutUsComponent;
